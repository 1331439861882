import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
// style
import './ModalAlertActionStyle.scss';
// component
import { ModalInformationPopUp, ButtonMain, LabelMultiple } from '../../Index';

const ModalAlertAction = props => {
  const {
    onCloseModal,
    buttonAdditionalSecondText,
    buttonAdditionalText,
    buttonSubmitText,
    buttonType,
    customElementProps,
    modalDetail,
    onButtonAdditionalSubmit,
    onButtonSecondAdditionalSubmit,
    onButtonSubmit,
    openModal,
    note,
    isLoading,
    modalType,
    size,
    disableApplyBtn,
  } = props;

  const renderModalBody = () => {
    let renderElement = null;
    renderElement = (
      <Grid container direction="column" className="flex-wrap-unset">
        <Grid item lg md className={`section-header-modal-action-stand-alone ${modalType}`}>
          <Grid container>
            <Grid item lg={11} md={11}>
              <label
                className={`text-18 text-bold wrapping-container ${modalType === 'primary' &&
                  'text-white'}`}
              >
                {modalDetail.title}
              </label>
            </Grid>
            <Grid item lg md>
              <Grid container direction="column" justify="flex-end">
                <Grid item>
                  <div className="container-button-close">
                    <IconButton onClick={onCloseModal}>
                      <i className="ic-ffo-close container-icon-prefix size-16" />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className="section-body-modal-action-stand-alone">
          <Grid container direction="column" className="flex-wrap-unset">
            {note ? (
              <Grid item lg md className="container-note">
                <Grid container direction="column">
                  <Grid item lg md>
                    <label className="text-12 ">*{note.title}</label>
                  </Grid>
                </Grid>
                <Grid item lg md>
                  <LabelMultiple dataArray={note.body} />
                </Grid>
              </Grid>
            ) : null}
            {modalDetail.body ? (
              <Grid item lg md className="container-body">
                <label className="text-18">{modalDetail.body}</label>
              </Grid>
            ) : null}
            <Grid item lg md>
              {customElementProps}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md>
          <div className="divider-hr" />
        </Grid>
        <Grid item lg md className="section-footer-modal-action-stand-alone">
          <Grid container spacing={2} direction="row" justify="space-between">
            <Grid item lg md>
              {props.additionalSecondButton ? (
                <Grid item lg={6} md={6}>
                  <ButtonMain
                    labelText={buttonAdditionalSecondText}
                    onClick={onButtonSecondAdditionalSubmit}
                    type={buttonType || 'outlined-primary'}
                    size="md"
                    isLoading={isLoading}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Grid item lg md>
              <Grid container spacing={2} justify="flex-end">
                {props.additionalButton && (
                  <Grid item lg={6} md={6}>
                    <ButtonMain
                      labelText={buttonAdditionalText}
                      onClick={onButtonAdditionalSubmit}
                      type={buttonType || 'outlined-primary'}
                      size="md"
                      isLoading={isLoading}
                    />
                  </Grid>
                )}
                <Grid item lg={6} md={6}>
                  <ButtonMain
                    labelText={buttonSubmitText}
                    onClick={onButtonSubmit}
                    type={buttonType || 'primary'}
                    size="md"
                    isLoading={isLoading}
                    disabled={disableApplyBtn}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

    return renderElement;
  };

  return (
    <ModalInformationPopUp
      isOpen={openModal}
      onClose={onCloseModal}
      customElementProps={renderModalBody()}
      size={size}
    />
  );
};

ModalAlertAction.propTypes = {
  additionalButton: PropTypes.bool,
  additionalSecondButton: PropTypes.bool,
  buttonAdditionalSecondText: PropTypes.string,
  buttonAdditionalText: PropTypes.string,
  buttonSubmitText: PropTypes.string,
  buttonType: PropTypes.string,
  customElementProps: PropTypes.element,
  disableApplyBtn: PropTypes.bool,
  isLoading: PropTypes.bool,
  modalDetail: PropTypes.object,
  modalType: PropTypes.string,
  note: PropTypes.object,
  onButtonAdditionalSubmit: PropTypes.func,
  onButtonSecondAdditionalSubmit: PropTypes.func,
  onButtonSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
  openModal: PropTypes.bool,
  size: PropTypes.string,
};

ModalAlertAction.defaultProps = {
  disableApplyBtn: false,
};

export default ModalAlertAction;
