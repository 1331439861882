/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';
// Style
import './ButtonMainStyle.scss';
import { PermissionHelper } from '../../../helpers/PermissionHelper';

const ButtonMain = props => {
  const {
    labelText,
    onClick,
    isShadow,
    startIcon,
    endIcon,
    type,
    rounded,
    size,
    isLoading,
    disabled,
    requiredPermission,
  } = props;

  let hasPermission = true;
  if (!_.isEmpty(requiredPermission)) {
    const permissionKeys = requiredPermission.split('.');
    const module = permissionKeys[0];
    const page = permissionKeys[1];
    const access = permissionKeys[2];

    hasPermission = PermissionHelper.checkUserHasPermission(module, page, access);
  }

  if (!hasPermission) {
    return null;
  }

  let currentSize = 'text-12';
  let currentCircular = 14;
  let currentIcon = 'text-16';
  let currentMargin = 'mv-8';

  switch (size) {
    case 'xl':
      currentSize = 'text-16';
      currentCircular = 18;
      currentIcon = 'size-24';
      currentMargin =
        type === 'ghost' || type === 'sillhoutte' || type === 'outlined-primary' ? 'mv-6' : 'mv-8';
      break;
    case 'md':
      currentSize = 'text-14';
      currentCircular = 16;
      currentIcon = 'size-22';
      currentMargin =
        type === 'ghost' || type === 'sillhoutte' || type === 'outlined-primary' ? 'mv-6' : 'mv-7';
      break;
    default:
      currentSize = 'text-12';
      currentCircular = 14;
      currentIcon = 'size-16';
      currentMargin = '';
      break;
  }

  return (
    <Button
      className={`button-main btn-${type} btn-${size} ${rounded ? 'btn-rounded' : ''}`}
      disableElevation={isShadow}
      onClick={onClick}
      startIcon={startIcon && <i className={`${startIcon} container-icon-prefix ${currentIcon}`} />}
      endIcon={endIcon && <i className={`${endIcon} container-icon-prefix ${currentIcon}`} />}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <CircularProgress className="btn-loading" size={currentCircular} />
      ) : (
        <label className={`${currentSize} ${currentMargin}`}>{labelText}</label>
      )}
    </Button>
  );
};

ButtonMain.propTypes = {
  disabled: PropTypes.bool,
  endIcon: PropTypes.string,
  isLoading: PropTypes.bool,
  isShadow: PropTypes.bool,
  labelText: PropTypes.string,
  onClick: PropTypes.func,
  requiredPermission: PropTypes.string,
  rounded: PropTypes.bool,
  size: PropTypes.string,
  startIcon: PropTypes.string,
  type: PropTypes.string,
};

ButtonMain.defaultProps = {
  size: 'md',
};

export default ButtonMain;
