import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PropTypes from 'prop-types';
import { Form } from 'antd';
// Style
import './TextEditorStyle.scss';
import { Grid } from '@material-ui/core';
import ValidationHelper from '../../../helpers/ValidationHelper';

const TextEditor = props => {
  const { currentValue, errorMessage, maxLength, validateStatus, onChange, modules } = props;

  const [valueState, setValue] = React.useState(currentValue || '');
  const [stringLength, setStringLength] = React.useState(0);

  React.useEffect(() => {
    setValue(currentValue || '');
  }, [currentValue]);

  const onChangeTextInput = (content, delta, source, editor) => {
    const plainText = editor.getText();
    const calculatedLength = ValidationHelper.validateWord(plainText.trim());

    if (maxLength && calculatedLength > maxLength) {
      return;
    }

    setValue(content);
    setStringLength(calculatedLength);

    if (content === '<p><br></p>') {
      onChange('');
    } else {
      onChange(content);
    }
  };

  return (
    <Form.Item
      validateStatus={validateStatus}
      help={errorMessage}
      className="container-text-editor"
    >
      <ReactQuill
        className={validateStatus === 'error' ? 'quill-error' : ''}
        theme="snow"
        modules={modules}
        value={valueState}
        onChange={onChangeTextInput}
      />
      {maxLength ? (
        <Grid container justify="flex-end">
          <Grid item lg={3} md={3} className="container-content-length">
            <label className="text-10">{`${stringLength} / ${maxLength}`}</label>
          </Grid>
        </Grid>
      ) : null}
    </Form.Item>
  );
};

TextEditor.propTypes = {
  currentValue: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  modules: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  validateStatus: PropTypes.string,
};

TextEditor.defaultProps = {
  currentValue: '',
  errorMessage: '',
  maxLength: null,
  modules: {},
  validateStatus: '',
};

export default TextEditor;
