import React from 'react';
import { ColorPicker } from 'antd';
import PropTypes from 'prop-types';

const ColorsPicker = ({ value, onChange }) => {
  const handleChange = color => {
    const hexColor = color.toHexString();
    onChange(hexColor);
  };

  return <ColorPicker value={value} showText format="hex" onChange={handleChange} />;
};

ColorsPicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

ColorsPicker.defaultProps = {
  value: '#1677FF',
};

export default ColorsPicker;
