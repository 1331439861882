/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { FormLabel } from '@material-ui/core';
// style
import './LabelInputStyle.scss';

const LabelInput = props => {
  const { additionalText, isRequired, labelText, showDelete, deleteText, onDelete } = props;

  const wording = `${labelText} ${additionalText}`;

  return (
    <div
      className="container-label-input"
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
      <FormLabel component="label" className="form-label" required={isRequired} style={{ flex: 1 }}>
        {wording.trim()}
      </FormLabel>
      {showDelete && onDelete && (
        <label className="delete-waiver-button" onClick={onDelete}>
          {deleteText}
        </label>
      )}
    </div>
  );
};

LabelInput.propTypes = {
  additionalText: PropTypes.string,
  deleteText: PropTypes.string,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  onDelete: PropTypes.func,
  showDelete: PropTypes.bool,
};

LabelInput.defaultProps = {
  additionalText: '',
  isRequired: false,
  onDelete: null,
};

export default LabelInput;
