/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import _ from 'lodash';
// Style
import './GeneratorPdfStyle.scss';
// component
import { ButtonMain } from '../../Index';
import {
  LabelShipping,
  LabelShipping02,
  CanvasHandoverList,
  CanvasDashboardMembershipList,
  CanvasDashboardRecentList,
  CanvasProformaInvoice,
  CanvasDeliverySlip,
  CanvasPOInvoice,
  LabelMeal,
  LabelPatient,
  EatAtStoreInvoice,
  EatCateringInvoice,
  EatCaptainOrderInvoice,
  FoodlistSummaryLabel,
  LabelMealAddress,
  CanvasEventKitReceipt,
} from './components/Index';
// helper
import { CommonHelper } from '../../../helpers/Index';
import ButtonIconMain from '../button-icon-main/ButtonIconMain';

const GeneratorPdf = props => {
  const {
    mode,
    fileName,
    data,
    buttonSize,
    buttonType,
    buttonLabel,
    customLabel,
    onClick,
    iconPrefix,
    isButtonIcon,
    isDisabled,
    isLoading,
    isFuncOnly,
    roomName,
    callbackMessage,
  } = props;

  const checkIfDownload = mode === 'download';

  const [valueState, setValue] = React.useState(checkIfDownload);

  const MyDocument = () => {
    let renderElement = <LabelShipping data={data} />;
    switch (customLabel) {
      case 'label-shipping':
        renderElement = <LabelShipping data={data} />;
        break;
      case 'label-shipping-02':
        renderElement = <LabelShipping02 data={data} />;
        break;
      case 'canvas-handover-list':
        renderElement = <CanvasHandoverList data={data} />;
        break;
      case 'canvas-dashboard-membership-list':
        renderElement = <CanvasDashboardMembershipList data={data} />;
        break;
      case 'canvas-dashboard-recent-list':
        renderElement = <CanvasDashboardRecentList data={data} />;
        break;
      case 'canvas-proforma-invoice':
        renderElement = <CanvasProformaInvoice data={data} />;
        break;
      case 'canvas-purchaser-order-delivery-slip':
        renderElement = <CanvasDeliverySlip data={data} />;
        break;
      case 'canvas-purchaser-order-invoice':
        renderElement = <CanvasPOInvoice data={data} />;
        break;
      case 'label-meal':
        renderElement = <LabelMeal data={data} />;
        break;
      case 'label-patient':
        renderElement = <LabelPatient data={data} roomName={roomName} />;
        break;
      case 'catering-rspad':
        renderElement = <EatCateringInvoice data={data} />;
        break;
      case 'eat-at-store-invoice':
        renderElement = <EatAtStoreInvoice data={data} />;
        break;
      case 'captain-order':
        renderElement = <EatCaptainOrderInvoice data={data} />;
        break;
      case 'foodlist-summary-label':
        renderElement = <FoodlistSummaryLabel data={data} />;
        break;
      case 'label-meal-address':
        renderElement = <LabelMealAddress data={data} />;
        break;
      case 'event-kit-receipt':
        renderElement = <CanvasEventKitReceipt data={data} />;
        break;

      default:
        break;
    }

    return renderElement;
  };

  const processMessage = (messages, type) => {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };
    callbackMessage(paramInformation);
  };

  const handleButtonClick = () => {
    if (onClick) {
      setValue(true);
      onClick();
    } else if (_.isEmpty(data)) {
      processMessage('There is no data available', 'warning');
    } else if (!onClick && !_.isEmpty(data)) {
      setValue(true);
    }
  };

  const PdfAction = () => {
    let renderElement = (
      <BlobProvider document={<MyDocument />}>
        {({ blob, url, loading, error }) => {
          if (!loading && _.isEmpty(data) === false) {
            const file = new Blob([blob], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, '_Blank');
            window.focus();
            setTimeout(() => {
              setValue(false);
            }, 2000);
          }
          return <div />;
        }}
      </BlobProvider>
    );

    if (mode === 'download') {
      renderElement = (
        <PDFDownloadLink document={<MyDocument />} fileName={fileName}>
          {({ blob, url, loading, error }) =>
            loading && !_.isEmpty(data) ? (
              'Loading document...'
            ) : (
              <ButtonMain
                type={buttonType}
                size={buttonSize}
                labelText={buttonLabel}
                startIcon={iconPrefix}
              />
            )
          }
        </PDFDownloadLink>
      );
    }

    return renderElement;
  };

  const RenderButton = () => {
    let renderElement = (
      <ButtonMain
        type={buttonType}
        size={buttonSize}
        labelText={buttonLabel}
        onClick={handleButtonClick}
        isLoading={valueState || isLoading}
        startIcon={iconPrefix}
        disabled={isDisabled || false}
      />
    );

    if (isButtonIcon) {
      renderElement = (
        <ButtonIconMain
          icon={iconPrefix}
          type="ghost"
          size={buttonSize}
          onClick={handleButtonClick}
          isLoading={valueState || isLoading}
          disabled={isDisabled || false}
        />
      );
    }

    return renderElement;
  };

  return !isFuncOnly ? (
    <div>
      {!checkIfDownload ? <RenderButton /> : null}
      {valueState && <PdfAction />}
    </div>
  ) : (
    !_.isEmpty(data) && <PdfAction />
  );
};

GeneratorPdf.propTypes = {
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonType: PropTypes.string,
  callbackMessage: PropTypes.func,
  customLabel: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fileName: PropTypes.string,
  iconPrefix: PropTypes.string,
  isButtonIcon: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isFuncOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  roomName: PropTypes.string,
};

GeneratorPdf.defaultProps = {
  buttonLabel: 'Print',
  buttonType: 'ghost',
  fileName: 'download.pdf',
  mode: 'print',
};

export default GeneratorPdf;
