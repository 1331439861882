import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  PrevNextStepperCount,
  CollapsiblePanel,
  LabelInput,
  TextInput,
  TextEditor,
  ButtonMain,
} from '../../../../../components/Index';
// style
import '../../EventCMSAddStyle.scss';

class StepCreateWaiver extends React.Component {
  handleAddItem = key => {
    const { initialForm, form, onChange } = this.props;
    const defaultValue = initialForm[key].lists[0] || {};
    const updatedList = [...(form[key].lists || []), { ...defaultValue }];
    onChange(`${key}.lists`, updatedList);
  };

  handleRemoveItem = (key, index) => {
    const { form, onChange } = this.props;
    const updatedList = form[key].lists.filter((_, i) => i !== index) || [];
    onChange(`${key}.lists`, updatedList);
  };

  getItems = (form, validation, panelStyle, onChange) => [
    {
      key: '1',
      label: 'Event Waiver',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Information" isRequired />
                <TextInput
                  placeHolderText="Enter Information"
                  currentValue={form.waiver.title}
                  size="md"
                  isError={!!validation['waiver.title']}
                  errorMessage={validation['waiver.title']}
                  onChange={value => onChange('waiver.title', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form.waiver.lists.map((item, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item lg={12} md={12}>
                <FormControl component="fieldset" fullWidth margin="normal">
                  <LabelInput
                    labelText={`Waiver ${index + 1}`}
                    isRequired
                    showDelete={form.waiver.lists.length > 1}
                    deleteText="Delete this waiver"
                    onDelete={() => this.handleRemoveItem('waiver', index)}
                  />
                  <TextEditor
                    currentValue={item.description}
                    validateStatus={!!validation[`waiver.lists[${index}].description`]}
                    errorMessage={validation[`waiver.lists[${index}].description`]}
                    onChange={value => onChange(`waiver.lists[${index}].description`, value)}
                  />
                </FormControl>
              </Grid>
            </Grid>
          ))}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2}>
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Waiver"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('waiver')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Event Terms & Conditions',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Indonesia" isRequired />
                <TextEditor
                  currentValue={form.waiver.tncId}
                  validateStatus={!!validation['waiver.tncId']}
                  errorMessage={validation['waiver.tncId']}
                  onChange={value => onChange('waiver.tncId', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="English" isRequired />
                <TextEditor
                  currentValue={form.waiver.tncEng}
                  validateStatus={!!validation['waiver.tncEng']}
                  errorMessage={validation['waiver.tncEng']}
                  onChange={value => onChange('waiver.tncEng', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
  ];

  render() {
    const { form, validation, onChange } = this.props;
    const panelStyle = {
      border: '1px solid #F0F0F0',
      borderRadius: 8,
      backgroundColor: 'white',
      marginBottom: 24,
      padding: '16px',
    };

    return <CollapsiblePanel items={this.getItems(form, validation, panelStyle, onChange)} />;
  }
}

StepCreateWaiver.propTypes = {
  form: PropTypes.object,
  initialForm: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateWaiver));
