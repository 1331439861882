import { constants } from '../reducers/EventReducer';

export function getEventPaginationRequest() {
  return { type: constants.getEventPaginationRequest };
}
export function getEventPaginationSuccess(response) {
  return { type: constants.getEventPaginationSuccess, payload: response };
}
export function getEventPaginationFail(response) {
  return { type: constants.getEventPaginationFail, payload: response.data };
}

export function getEventCategoryRequest() {
  return { type: constants.getEventCategoryRequest };
}
export function getEventCategorySuccess(response) {
  return { type: constants.getEventCategorySuccess, payload: response };
}
export function getEventCategoryFail(response) {
  return { type: constants.getEventCategoryFail, payload: response.data };
}

export function createEventRequest() {
  return { type: constants.createEventRequest };
}
export function createEventSuccess(response) {
  return { type: constants.createEventSuccess, payload: response };
}
export function createEventFail(response) {
  return { type: constants.createEventFail, payload: response.data };
}

export function getEventParticipantPaginationRequest() {
  return { type: constants.getEventParticipantPaginationRequest };
}
export function getEventParticipantPaginationSuccess(response) {
  return { type: constants.getEventParticipantPaginationSuccess, payload: response };
}
export function getEventParticipantPaginationFail(response) {
  return { type: constants.getEventParticipantPaginationFail, payload: response.data };
}

export function getEventParticipantDetailsRequest() {
  return { type: constants.getEventParticipantDetailsRequest };
}
export function getEventParticipantDetailsSuccess(response) {
  return { type: constants.getEventParticipantDetailsSuccess, payload: response };
}
export function getEventParticipantDetailsFail(response) {
  return { type: constants.getEventParticipantDetailsFail, payload: response.data };
}

export function getEventDetailsRequest() {
  return { type: constants.getEventDetailsRequest };
}
export function getEventDetailsSuccess(response) {
  return { type: constants.getEventDetailsSuccess, payload: response };
}
export function getEventDetailsFail(response) {
  return { type: constants.getEventDetailsFail, payload: response.data };
}

export function getEventStatisticRequest() {
  return { type: constants.getEventStatisticRequest };
}
export function getEventStatisticSuccess(response) {
  return { type: constants.getEventStatisticSuccess, payload: response };
}
export function getEventStatisticFail(response) {
  return { type: constants.getEventStatisticFail, payload: response.data };
}

export function resetParticipantDetails() {
  return { type: constants.resetParticipantDetails };
}

export function setSelfClaimRequest() {
  return { type: constants.setSelfClaimRequest };
}
export function setSelfClaimSuccess(response) {
  return { type: constants.setSelfClaimSuccess, payload: response };
}
export function setSelfClaimFail(response) {
  return { type: constants.setSelfClaimFail, payload: response.data };
}

export function setDelegateClaimRequest() {
  return { type: constants.setDelegateClaimRequest };
}
export function setDelegateClaimSuccess(response) {
  return { type: constants.setDelegateClaimSuccess, payload: response };
}
export function setDelegateClaimFail(response) {
  return { type: constants.setDelegateClaimFail, payload: response.data };
}

export function getEventParticipantDataRequest() {
  return { type: constants.getEventParticipantDataRequest };
}
export function getEventParticipantDataSuccess(response) {
  return { type: constants.getEventParticipantDataSuccess, payload: response };
}
export function getEventParticipantDataFail(response) {
  return { type: constants.getEventParticipantDataFail, payload: response.data };
}

export function updateParticipantDataRequest() {
  return { type: constants.updateParticipantDataRequest };
}
export function updateParticipantDataSuccess(response) {
  return { type: constants.updateParticipantDataSuccess, payload: response };
}
export function updateParticipantDataFail(response) {
  return { type: constants.updateParticipantDataFail, payload: response.data };
}

export function resendEmailRequest() {
  return { type: constants.resendEmailRequest };
}
export function resendEmailSuccess(response) {
  return { type: constants.resendEmailSuccess, payload: response };
}
export function resendEmailFail(response) {
  return { type: constants.resendEmailFail, payload: response.data };
}

export function createEventContentRequest() {
  return { type: constants.createEventContentRequest };
}
export function createEventContentSuccess(response) {
  return { type: constants.createEventContentSuccess, payload: response };
}
export function createEventContentFail(response) {
  return { type: constants.createEventContentFail, payload: response.data };
}

export function getEventContentRequest() {
  return { type: constants.getEventContentRequest };
}
export function getEventContentSuccess(response) {
  return { type: constants.getEventContentSuccess, payload: response };
}
export function getEventContentFail(response) {
  return { type: constants.getEventContentFail, payload: response.data };
}
