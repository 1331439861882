import React from 'react';
import PropTypes from 'prop-types';
import { Page, Text, View, Document, Image, StyleSheet } from '@react-pdf/renderer';
import { CommonHelper } from '../../../../../helpers/Index';
import { Images, StyleJs as StyleJsGlobal } from '../../../../../assets/Index';

const styles = StyleSheet.create({
  page: {},
  container: {
    padding: 24,
  },
  header: {
    marginBottom: 40,
  },
  greeting: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  detailsContainer: {
    borderRadius: 4,
    backgroundColor: '#FFF8E7',
    paddingVertical: 24,
    paddingHorizontal: 110,
    marginBottom: 20,
  },
  detailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  labelContainer: {
    flex: 1,
    marginRight: 15,
  },
  label: {
    color: '#BD8C19',
    fontSize: 12,
    fontWeight: 'bold',
  },
  valueContainer: {
    flex: 2,
  },
  value: {
    fontSize: 12,
    color: '#333333',
  },
  footerContainer: {
    backgroundColor: '#F0E1BE',
    padding: 24,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerText: {
    color: '#100C2B',
    fontSize: 14,
    fontWeight: 'bold',
  },
  section: {
    flex: 1,
    paddingHorizontal: 10,
  },
  contactInfo: {
    marginTop: 12,
  },
  socialIcons: {
    flexDirection: 'row',
    marginTop: 8,
  },
  storeButtons: {
    flexDirection: 'row',
    marginTop: 12,
  },
  storeButton: {
    backgroundColor: '#000',
    padding: 10,
    marginRight: 8,
    borderRadius: 5,
  },
  storeText: {
    color: '#FFF',
    fontSize: 12,
  },
  iconText: {
    fontSize: 12,
    fontWeight: 'normal',
    marginVertical: 4,
  },
});

const CanvasEventKitReceipt = ({ data }) => {
  const letterheadLogo = Images.eventHeader;
  const appStoreLogo = Images.appStore;
  const googlePlayLogo = Images.googlePlayStore;
  const whatsappLogo = Images.whatsapp;
  const phoneLogo = Images.phone;
  const instagramLogo = Images.instagram;
  const tiktokLogo = Images.tiktok;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[StyleJsGlobal.gridItem12]}>
          <Image src={letterheadLogo} style={[styles.header]} />
        </View>
        <View style={styles.container}>
          <Text style={styles.greeting}>{`Dear, ${data.name}!`}</Text>
          <Text style={{ fontSize: 12, marginBottom: 40 }}>
            {`Your event kit for ${data.ticket_category} has been successfully claimed. Here are the details:`}
          </Text>
          <View style={styles.detailsContainer}>
            <View style={styles.detailRow}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Order ID</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>{data.registration_id}</Text>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Claimed Date</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>
                  {CommonHelper.dateTimeParseNewFormat(
                    data.claimed_at,
                    'dddd, DD MMMM YYYY / HH:mm:ss',
                  )}
                </Text>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Claim Type</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>
                  {data.self_claimed ? 'Self Claim' : 'Claim Via Representative'}
                </Text>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>{`Delegation's Name`}</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>
                  {data.delegate_information ? data.delegate_information.name : '-'}
                </Text>
              </View>
            </View>
            <View style={styles.detailRow}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Email</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>{data.email}</Text>
              </View>
            </View>
            <View style={[styles.detailRow, { marginBottom: 0 }]}>
              <View style={styles.labelContainer}>
                <Text style={styles.label}>Phone Number</Text>
              </View>
              <View style={styles.valueContainer}>
                <Text style={styles.value}>{data.phone}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={[styles.footerContainer]}>
          <View style={[styles.rowContainer]}>
            <View style={[styles.section]}>
              <Text style={styles.footerText}>Help Center</Text>
              <View style={styles.contactInfo}>
                <View style={[StyleJsGlobal.flexRow, StyleJsGlobal.alignItemCenter]}>
                  <Image src={whatsappLogo} style={{ width: 16, height: 16, marginRight: 5 }} />
                  <Text style={styles.iconText}>081111820906</Text>
                </View>
                <View style={[StyleJsGlobal.flexRow, StyleJsGlobal.alignItemCenter]}>
                  <Image src={phoneLogo} style={{ width: 16, height: 16, marginRight: 5 }} />
                  <Text style={styles.iconText}>081111820906</Text>
                </View>
              </View>
            </View>
            <View style={[styles.section]}>
              <Text style={styles.footerText}>Connect with us</Text>
              <View style={styles.contactInfo}>
                <View style={[StyleJsGlobal.flexRow, StyleJsGlobal.alignItemCenter]}>
                  <Image src={instagramLogo} style={{ width: 20, height: 20, marginRight: 8 }} />
                  <Image src={tiktokLogo} style={{ width: 20, height: 20 }} />
                </View>
              </View>
            </View>
            <View style={[styles.section]}>
              <Text style={styles.footerText}>Find us on</Text>
              <View style={styles.storeButtons}>
                <Image src={appStoreLogo} style={{ marginRight: 10 }} />
                <Image src={googlePlayLogo} />
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

CanvasEventKitReceipt.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default CanvasEventKitReceipt;
