/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Form } from 'antd';
import { LoadingOutlined, PlusOutlined, FilePdfOutlined } from '@ant-design/icons';
import { uploadFile } from '../../../services/api/UploadApi';
import { FileHelper } from '../../../helpers/Index';
import './UploadImageStyle.scss';

function beforeUpload(file, maxSize, allowedFileTypes) {
  const isAllowedType = allowedFileTypes.includes(file.type);

  if (!isAllowedType) {
    message.error(`You can only upload files of type: ${allowedFileTypes.join(', ')}!`);
  }

  const isSize = file.size / 1024 / 1024 < maxSize;
  if (!isSize) {
    message.error(`File must be smaller than ${maxSize}MB!`);
  }

  return isAllowedType && isSize;
}

const UploadImageUrl = ({
  allowedFileTypes,
  defaultValue,
  errorMessage,
  filePath,
  height,
  maxSize,
  onChange,
  setWidth,
  validateStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState(null);

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const base64 = await new Promise((resolve, reject) => {
        FileHelper.getBase64(file, result => {
          if (result) resolve(result);
          else reject(new Error('Failed to convert file to base64'));
        });
      });
      const reformatBase64 = base64.replace(/^data:image\/\w+;base64,/, '');

      const body = {
        prefix_file_name: '',
        file_path: filePath,
        file: reformatBase64,
      };
      const response = await uploadFile(body);
      const fileUrl = response.data || '';
      onChange(fileUrl);
      onSuccess('Upload successful!');
    } catch (error) {
      onError(error);
      message.error('File upload failed.');
    }
  };

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setFileType(info.file.type);
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Choose File...</div>
    </div>
  );

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <div
        className="container-upload-image"
        style={{ height: height || 225, marginBottom: defaultValue ? 15 : 0 }}
      >
        <Upload
          listType="picture-card"
          showUploadList={false}
          customRequest={customRequest}
          beforeUpload={file => beforeUpload(file, maxSize, allowedFileTypes)}
          onChange={handleChange}
        >
          {defaultValue ? (
            <>
              {fileType === 'application/pdf' ? (
                <div className="flex-column">
                  <FilePdfOutlined style={{ fontSize: '48px', color: '#f5222d' }} />
                  <a className="text-12 mt-8" onClick={() => {}}>
                    Change File...
                  </a>
                </div>
              ) : (
                <div className="container-img">
                  <img src={defaultValue} alt="avatar" style={{ width: setWidth || '100%' }} />
                  <a
                    className="text-12"
                    onClick={() => {
                      onChange(null);
                    }}
                  >
                    Change Image...
                  </a>
                </div>
              )}
            </>
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
    </Form.Item>
  );
};

UploadImageUrl.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  filePath: PropTypes.string,
  height: PropTypes.string,
  maxSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  setWidth: PropTypes.string,
  validateStatus: PropTypes.string,
};

UploadImageUrl.defaultProps = {
  allowedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
};

export default UploadImageUrl;
