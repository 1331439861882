import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, message, Modal, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uploadFile } from '../../../services/api/UploadApi';
import { FileHelper } from '../../../helpers/Index';

const MultipleUploadImage = ({
  allowedFileTypes = ['image/jpeg', 'image/png'],
  errorMessage,
  filePath,
  maxCount = 5,
  maxSize = 2,
  onChange,
  validateStatus,
  value = [],
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [fileList, setFileList] = useState(
    value.map((url, index) => ({
      uid: `-${index}`,
      name: `image-${index}`,
      status: 'done',
      url,
    })),
  );

  useEffect(() => {
    setFileList(
      value.map((url, index) => ({
        uid: `-${index}`,
        name: `image-${index}`,
        status: 'done',
        url,
      })),
    );
  }, [value]);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        FileHelper.getBase64(file.originFileObj, result => {
          if (result) resolve(result);
          else reject(new Error('Failed to convert file to base64'));
        });
      });
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };

  const handleRemove = file => {
    setFileList(prev => prev.filter(item => item.uid !== file.uid));
    onChange(fileList.filter(item => item.uid !== file.uid).map(item => item.url));
  };

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = file => {
    const isAllowedType = allowedFileTypes.includes(file.type);
    if (!isAllowedType) {
      message.error(`You can only upload files of type: ${allowedFileTypes.join(', ')}!`);
    }

    const isSizeValid = file.size / 1024 / 1024 < maxSize;
    if (!isSizeValid) {
      message.error(`File must be smaller than ${maxSize}MB!`);
    }

    return isAllowedType && isSizeValid;
  };

  const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
    const newFile = {
      uid: file.uid,
      name: file.name,
      status: 'uploading',
      percent: 0,
    };

    setFileList(prev => [...prev, newFile]);

    try {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 20;
        if (percent >= 100) {
          clearInterval(interval);
        } else {
          onProgress({ percent });
          setFileList(prev => prev.map(f => (f.uid === file.uid ? { ...f, percent } : f)));
        }
      }, 300);

      const base64 = await new Promise((resolve, reject) => {
        FileHelper.getBase64(file, result => {
          if (result) resolve(result);
          else reject(new Error('Failed to convert file to base64'));
        });
      });

      const reformatBase64 = base64.replace(/^data:image\/\w+;base64,/, '');
      const body = { file_path: filePath, file: reformatBase64 };
      const response = await uploadFile(body);

      const uploadedFileUrl = response.data || '';

      setFileList(prev => {
        const updatedFileList = prev.map(f =>
          f.uid === file.uid ? { ...f, status: 'done', url: uploadedFileUrl, percent: 100 } : f,
        );
        onChange(updatedFileList.map(f => f.url));
        return updatedFileList;
      });

      onSuccess('Upload successful!');
    } catch (error) {
      setFileList(prev =>
        prev.map(f => (f.uid === file.uid ? { ...f, status: 'error', percent: 0 } : f)),
      );
      onError(error);
      message.error('File upload failed.');
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <Form.Item validateStatus={validateStatus} help={errorMessage}>
      <Upload
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
      >
        {fileList.length >= maxCount ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title="Preview" footer={null} onCancel={handleCancel}>
        <img alt="logo" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Form.Item>
  );
};

MultipleUploadImage.propTypes = {
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  filePath: PropTypes.string,
  maxCount: PropTypes.number,
  maxSize: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  validateStatus: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default MultipleUploadImage;
