import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  PrevNextStepperCount,
  CollapsiblePanel,
  UploadImageUrl,
  LabelInput,
  TextInput,
  TextEditor,
  ColorsPicker,
  MultipleUploadImage,
  ButtonMain,
} from '../../../../../components/Index';
// style
import '../../EventCMSAddStyle.scss';

class StepCreateDetails extends React.Component {
  handleAddItem = key => {
    const { initialForm, form, onChange } = this.props;
    const defaultValue = initialForm[key].lists[0] || {};
    const updatedList = [...(form[key].lists || []), { ...defaultValue }];
    onChange(`${key}.lists`, updatedList);
  };

  handleRemoveItem = (key, index) => {
    const { form, onChange } = this.props;
    const updatedList = form[key].lists.filter((_, i) => i !== index) || [];
    onChange(`${key}.lists`, updatedList);
  };

  getItems = (form, validation, panelStyle, onChange) => [
    {
      key: '1',
      label: 'Banner Hero',
      children: (
        <div className="flex-column" style={{ gap: '48px' }}>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Banner Hero" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.banner}
                    maxSize={1}
                    validateStatus={!!validation.banner}
                    errorMessage={validation.banner}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={url => onChange('banner', url)}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Event Logo" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.logo}
                    maxSize={1}
                    validateStatus={!!validation.logo}
                    errorMessage={validation.logo}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={item => onChange('logo', item)}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>

          <div>
            <label className="text-16 text-rolling-stone text-semi-bold">Running Text</label>
            {form.runningText.lists.map((item, index) => {
              return (
                <Grid container spacing={2} key={index}>
                  <Grid item lg={2} md={2}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Icon" isRequired />
                      <div className="container-image-action">
                        <UploadImageUrl
                          filePath={`/app/public/images/event/${form.eventId}/`}
                          defaultValue={item.icon}
                          maxSize={1}
                          height={160}
                          validateStatus={!!validation[`runningText.lists[${index}].icon`]}
                          errorMessage={validation[`runningText.lists[${index}].icon`]}
                          allowedFileTypes={['image/jpeg', 'image/png']}
                          onChange={val => onChange(`runningText.lists[${index}].icon`, val)}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item lg={10} md={10}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput
                        labelText="Text"
                        isRequired
                        showDelete={form.runningText.lists.length > 1}
                        deleteText="Delete"
                        onDelete={() => this.handleRemoveItem('runningText', index)}
                      />
                      <TextInput
                        placeHolderText="Enter Text"
                        currentValue={item.text}
                        size="md"
                        isError={!!validation[`runningText.lists[${index}].text`]}
                        errorMessage={validation[`runningText.lists[${index}].text`]}
                        onChange={value => onChange(`runningText.lists[${index}].text`, value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              );
            })}
            <Grid
              container
              justify="center"
              alignItems="center"
              className="container-filter flex-wrap-unset mt-16"
              style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
            >
              <Grid item xl={2} lg={2} md={2} className="row-filter">
                <ButtonMain
                  type="sillhoutte"
                  size="md"
                  labelText="Add Text"
                  startIcon="ic-ffo-add"
                  onClick={() => this.handleAddItem('runningText')}
                />
              </Grid>
            </Grid>
          </div>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Event Description',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Title" isRequired />
                <TextInput
                  placeHolderText="Enter Title"
                  currentValue={form.description.title}
                  size="md"
                  isError={!!validation['description.title']}
                  errorMessage={validation['description.title']}
                  onChange={value => onChange('description.title', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Highlighted Text" isRequired />
                <TextInput
                  placeHolderText="Enter Highlighted Text"
                  currentValue={form.description.highlightedText}
                  size="md"
                  isError={!!validation['description.highlightedText']}
                  errorMessage={validation['description.highlightedText']}
                  onChange={value => onChange('description.highlightedText', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={12} md={12}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Description" isRequired />
                <TextEditor
                  currentValue={form.description.description}
                  validateStatus={!!validation['description.description']}
                  errorMessage={validation['description.description']}
                  onChange={value => onChange('description.description', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Image" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.description.backgroundImage}
                    maxSize={1}
                    validateStatus={!!validation['description.backgroundImage']}
                    errorMessage={validation['description.backgroundImage']}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={item => onChange('description.backgroundImage', item)}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Select Text Color" />
                <ColorsPicker
                  value={form.description.color}
                  onChange={value => onChange('description.color', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Event Partner (Organizer/Sponsor)',
      children: (
        <div>
          {form.partners.lists.map((item, index) => {
            return (
              <div key={index}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Partner Category Title" isRequired />
                      <TextInput
                        placeHolderText="Enter Title"
                        currentValue={item.title}
                        size="md"
                        isError={!!validation[`partners.lists[${index}].title`]}
                        errorMessage={validation[`partners.lists[${index}].title`]}
                        onChange={value => onChange(`partners.lists[${index}].title`, value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg md>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Partner Logo" isRequired />
                      <MultipleUploadImage
                        filePath={`/app/public/images/event/${form.eventId}/`}
                        value={item.logo}
                        allowedFileTypes={['image/jpeg', 'image/png']}
                        maxCount={4}
                        maxSize={2}
                        validateStatus={!!validation[`partners.lists[${index}].logo`]}
                        errorMessage={validation[`partners.lists[${index}].logo`]}
                        onChange={updatedFileList => {
                          const updatedPartners = [...form.partners.lists];
                          updatedPartners[index].logo = updatedFileList;
                          onChange('partners.lists', updatedPartners);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {form.partners.lists.length > 1 && (
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="container-filter flex-wrap-unset mt-16"
                  >
                    <Grid item xl={2} lg={2} md={2} className="row-filter">
                      <ButtonMain
                        type="danger"
                        size="md"
                        labelText="Delete"
                        onClick={() => this.handleRemoveItem('partners', index)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={3} lg={3} md={3} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Event Partner"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('partners')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '4',
      label: 'Event Highlight',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Title" isRequired />
                <TextInput
                  placeHolderText="Enter Title"
                  currentValue={form.highlights.title}
                  size="md"
                  isError={!!validation['highlights.title']}
                  errorMessage={validation['highlights.title']}
                  onChange={value => onChange('highlights.title', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Color" />
                <ColorsPicker
                  value={form.highlights.backgroundColor}
                  onChange={value => onChange('highlights.backgroundColor', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form.highlights.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item lg={2} md={2}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Highlight Icon" isRequired />
                    <div className="container-image-action">
                      <UploadImageUrl
                        filePath={`/app/public/images/event/${form.eventId}/`}
                        defaultValue={item.icon}
                        maxSize={1}
                        height={160}
                        validateStatus={!!validation[`highlights.lists[${index}].icon`]}
                        errorMessage={validation[`highlights.lists[${index}].icon`]}
                        allowedFileTypes={['image/jpeg', 'image/png']}
                        onChange={val => onChange(`highlights.lists[${index}].icon`, val)}
                      />
                    </div>
                  </FormControl>
                </Grid>
                <Grid item lg={5} md={5}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Highlight Title" isRequired />
                    <TextInput
                      placeHolderText="Enter Text"
                      currentValue={item.title}
                      size="md"
                      isError={!!validation[`highlights.lists[${index}].title`]}
                      errorMessage={validation[`highlights.lists[${index}].title`]}
                      onChange={value => onChange(`highlights.lists[${index}].title`, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={5} md={5}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText="Highlight Description"
                      isRequired
                      showDelete={form.highlights.lists.length > 1}
                      deleteText="Delete this highlight"
                      onDelete={() => this.handleRemoveItem('highlights', index)}
                    />
                    <TextInput
                      placeHolderText="Enter Description"
                      currentValue={item.description}
                      size="md"
                      isError={!!validation[`highlights.lists[${index}].description`]}
                      errorMessage={validation[`highlights.lists[${index}].description`]}
                      onChange={value => onChange(`highlights.lists[${index}].description`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={3} lg={3} md={3} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Highlight"
                startIcon="ic-ffo-add"
                disabled={form.highlights.lists.length === 6}
                onClick={() => this.handleAddItem('highlights')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
  ];

  render() {
    const { form, validation, onChange } = this.props;
    const panelStyle = {
      border: '1px solid #F0F0F0',
      borderRadius: 8,
      backgroundColor: 'white',
      marginBottom: 24,
      padding: '16px',
    };

    return <CollapsiblePanel items={this.getItems(form, validation, panelStyle, onChange)} />;
  }
}

StepCreateDetails.propTypes = {
  form: PropTypes.object,
  initialForm: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateDetails));
