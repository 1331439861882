import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  PrevNextStepperCount,
  CollapsiblePanel,
  UploadImageUrl,
  LabelInput,
  TextInput,
  ColorsPicker,
  ButtonMain,
} from '../../../../../components/Index';
// style
import '../../EventCMSAddStyle.scss';

class StepCreateTimeline extends React.Component {
  handleAddItem = key => {
    const { initialForm, form, onChange } = this.props;
    const defaultValue = initialForm[key].lists[0] || {};
    const updatedList = [...(form[key].lists || []), { ...defaultValue }];
    onChange(`${key}.lists`, updatedList);
  };

  handleRemoveItem = (key, index) => {
    const { form, onChange } = this.props;
    const updatedList = form[key].lists.filter((_, i) => i !== index) || [];
    onChange(`${key}.lists`, updatedList);
  };

  getItems = (form, validation, panelStyle, onChange) => [
    {
      key: '1',
      label: 'Event Timeline',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Image" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.timeline.image}
                    maxSize={1}
                    validateStatus={!!validation['timeline.image']}
                    errorMessage={validation['timeline.image']}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={item => onChange('timeline.image', item)}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Color" />
                <ColorsPicker
                  value={form.timeline.backgroundColor}
                  onChange={value => onChange('timeline.backgroundColor', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={3} md={3}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Separate Color" />
                <ColorsPicker
                  value={form.timeline.color}
                  onChange={value => onChange('timeline.color', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form.timeline.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Title" isRequired />
                    <TextInput
                      placeHolderText="Enter Title"
                      currentValue={item.title}
                      size="md"
                      isError={!!validation[`timeline.lists[${index}].title`]}
                      errorMessage={validation[`timeline.lists[${index}].title`]}
                      onChange={value => onChange(`timeline.lists[${index}].title`, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText="Date"
                      isRequired
                      showDelete={form.timeline.lists.length > 1}
                      deleteText="Delete this timeline"
                      onDelete={() => this.handleRemoveItem('timeline', index)}
                    />
                    <TextInput
                      placeHolderText="Enter Date"
                      currentValue={item.date}
                      size="md"
                      isError={!!validation[`timeline.lists[${index}].date`]}
                      errorMessage={validation[`timeline.lists[${index}].date`]}
                      onChange={value => onChange(`timeline.lists[${index}].date`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={3} lg={3} md={3} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Timeline"
                startIcon="ic-ffo-add"
                disabled={form.timeline.lists.length === 5}
                onClick={() => this.handleAddItem('timeline')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Event Location',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={2} md={2}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Image" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.location.backgroundImage}
                    maxSize={1}
                    height={160}
                    validateStatus={!!validation['location.backgroundImage']}
                    errorMessage={validation['location.backgroundImage']}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={val => onChange('location.backgroundImage', val)}
                  />
                </div>
              </FormControl>
            </Grid>
            <Grid item lg={5} md={5}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Title" isRequired />
                <TextInput
                  placeHolderText="Enter Text"
                  currentValue={form.location.title}
                  size="md"
                  isError={!!validation['location.title']}
                  errorMessage={validation['location.title']}
                  onChange={value => onChange('location.title', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={5} md={5}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Description" isRequired />
                <TextInput
                  placeHolderText="Enter Description"
                  currentValue={form.location.description}
                  size="md"
                  isError={!!validation['location.description']}
                  errorMessage={validation['location.description']}
                  onChange={value => onChange('location.description', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form.location.lists.map((item, index) => {
            return (
              <div key={index}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Location Photo" isRequired />
                      <div className="container-image-action">
                        <UploadImageUrl
                          filePath={`/app/public/images/event/${form.eventId}/`}
                          defaultValue={item.locationPhoto}
                          maxSize={1}
                          validateStatus={!!validation[`location.lists[${index}].locationPhoto`]}
                          errorMessage={validation[`location.lists[${index}].locationPhoto`]}
                          allowedFileTypes={['image/jpeg', 'image/png']}
                          onChange={value =>
                            onChange(`location.lists[${index}].locationPhoto`, value)
                          }
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item lg={3} md={3}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Icon" isRequired />
                      <div className="container-image-action">
                        <UploadImageUrl
                          filePath={`/app/public/images/event/${form.eventId}/`}
                          defaultValue={item.icon}
                          maxSize={1}
                          validateStatus={!!validation[`location.lists[${index}].icon`]}
                          errorMessage={validation[`location.lists[${index}].icon`]}
                          allowedFileTypes={['image/jpeg', 'image/png']}
                          onChange={value => onChange(`location.lists[${index}].icon`, value)}
                        />
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Title" isRequired />
                      <TextInput
                        placeHolderText="Enter Title"
                        currentValue={item.title}
                        size="md"
                        isError={!!validation[`location.lists[${index}].title`]}
                        errorMessage={validation[`location.lists[${index}].title`]}
                        onChange={value => onChange(`location.lists[${index}].title`, value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Date and Time" isRequired />
                      <TextInput
                        placeHolderText="Example: 15 - 16 November 2024, 10:00 - 20:00"
                        currentValue={item.date}
                        size="md"
                        isError={!!validation[`location.lists[${index}].date`]}
                        errorMessage={validation[`location.lists[${index}].date`]}
                        onChange={value => onChange(`location.lists[${index}].date`, value)}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {form.location.lists.length > 1 && (
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="container-filter flex-wrap-unset mt-16"
                  >
                    <Grid item xl={2} lg={2} md={2} className="row-filter">
                      <ButtonMain
                        type="danger"
                        size="md"
                        labelText="Delete"
                        onClick={() => this.handleRemoveItem('location', index)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Location"
                startIcon="ic-ffo-add"
                disabled={form.location.lists.length === 4}
                onClick={() => this.handleAddItem('location')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Additional Information',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Image" isRequired />
                <div className="container-image-action">
                  <UploadImageUrl
                    filePath={`/app/public/images/event/${form.eventId}/`}
                    defaultValue={form.additionalInformation.backgroundImage}
                    maxSize={1}
                    validateStatus={!!validation['additionalInformation.backgroundImage']}
                    errorMessage={validation['additionalInformation.backgroundImage']}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={val => onChange('additionalInformation.backgroundImage', val)}
                  />
                </div>
              </FormControl>
            </Grid>
          </Grid>
          {form.additionalInformation.lists.map((item, index) => {
            return (
              <div key={index}>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={2}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Card Image" isRequired />
                      <div className="container-image-action">
                        <UploadImageUrl
                          filePath={`/app/public/images/event/${form.eventId}/`}
                          defaultValue={item.image}
                          maxSize={1}
                          height={160}
                          validateStatus={
                            !!validation[`additionalInformation.lists[${index}].image`]
                          }
                          errorMessage={validation[`additionalInformation.lists[${index}].image`]}
                          allowedFileTypes={['image/jpeg', 'image/png']}
                          onChange={val =>
                            onChange(`additionalInformation.lists[${index}].image`, val)
                          }
                        />
                      </div>
                    </FormControl>
                  </Grid>
                  <Grid item lg={10} md={10}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="Title" isRequired />
                          <TextInput
                            placeHolderText="Enter Title"
                            currentValue={item.title}
                            size="md"
                            isError={!!validation[`additionalInformation.lists[${index}].title`]}
                            errorMessage={validation[`additionalInformation.lists[${index}].title`]}
                            onChange={value =>
                              onChange(`additionalInformation.lists[${index}].title`, value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="Description" isRequired />
                          <TextInput
                            placeHolderText="Enter Description"
                            currentValue={item.description}
                            size="md"
                            isError={
                              !!validation[`additionalInformation.lists[${index}].description`]
                            }
                            errorMessage={
                              validation[`additionalInformation.lists[${index}].description`]
                            }
                            onChange={value =>
                              onChange(`additionalInformation.lists[${index}].description`, value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="Subtitle" isRequired />
                          <TextInput
                            placeHolderText="Enter Text"
                            currentValue={item.text}
                            size="md"
                            isError={!!validation[`additionalInformation.lists[${index}].text`]}
                            errorMessage={validation[`additionalInformation.lists[${index}].text`]}
                            onChange={value =>
                              onChange(`additionalInformation.lists[${index}].text`, value)
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="Link" />
                          <TextInput
                            placeHolderText="Enter Link"
                            currentValue={item.link}
                            size="md"
                            onChange={value =>
                              onChange(`additionalInformation.lists[${index}].link`, value)
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {form.additionalInformation.lists.length > 1 && (
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="container-filter flex-wrap-unset mt-16"
                  >
                    <Grid item xl={2} lg={2} md={2} className="row-filter">
                      <ButtonMain
                        type="danger"
                        size="md"
                        labelText="Delete"
                        onClick={() => this.handleRemoveItem('additionalInformation', index)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Card"
                startIcon="ic-ffo-add"
                disabled={form.additionalInformation.lists.length === 6}
                onClick={() => this.handleAddItem('additionalInformation')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
  ];

  render() {
    const { form, validation, onChange } = this.props;
    const panelStyle = {
      border: '1px solid #F0F0F0',
      borderRadius: 8,
      backgroundColor: 'white',
      marginBottom: 24,
      padding: '16px',
    };

    return <CollapsiblePanel items={this.getItems(form, validation, panelStyle, onChange)} />;
  }
}

StepCreateTimeline.propTypes = {
  form: PropTypes.object,
  initialForm: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateTimeline));
