import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';

const CollapsiblePanel = ({ items, defaultActiveKey, bordered = false, customStyle = {} }) => {
  return (
    <Collapse
      bordered={bordered}
      defaultActiveKey={defaultActiveKey}
      expandIconPosition="end"
      style={customStyle}
      items={items}
    />
  );
};

CollapsiblePanel.propTypes = {
  bordered: PropTypes.bool,
  customStyle: PropTypes.object,
  defaultActiveKey: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      key: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      style: PropTypes.object,
    }),
  ).isRequired,
};

CollapsiblePanel.defaultProps = {
  bordered: false,
  customStyle: {},
};

export default CollapsiblePanel;
