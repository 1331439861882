import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl, FormLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form, Table, Switch } from 'antd';
// component
import {
  PrevNextStepperCount,
  ButtonIconMain,
  ButtonMain,
  UploadImage,
  TextInput,
  LabelInput,
  PickerInputDate,
  PickerInputTime,
  TextInputNumber,
} from '../../../../../components/Index';
// helpers
import { CommonHelper } from '../../../../../helpers/Index';
// style
import '../../EventAddStyle.scss';

const initialForm = {
  eventKitId: null,
  title: '',
  quota: '',
  description: '',
  isActive: true,
};

class StepCreateKitPack extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formKitPack: CommonHelper.objectCloning(initialForm),
      editIndex: null,
    };
  }

  handleAddVariant = () => {
    const {
      form: { kitVariants },
      onChange,
    } = this.props;

    const updatedVariants = kitVariants ? [...kitVariants] : [];

    updatedVariants.push({
      id: updatedVariants.length + 1,
      title: '',
      quota: '',
      description: '',
      isActive: true,
    });

    onChange('kitVariants', updatedVariants);

    this.setState({
      editIndex: updatedVariants.length - 1,
      formKitPack: CommonHelper.objectCloning(initialForm),
    });
  };

  handleButtonEdit = index => {
    const {
      form: { kitVariants },
    } = this.props;

    const selectedVariant = kitVariants[index];

    this.setState({
      editIndex: index,
      formKitPack: {
        eventKitId: selectedVariant.eventKitId || null,
        title: selectedVariant.title || '',
        quota: selectedVariant.quota || '',
        description: selectedVariant.description || '',
        isActive: selectedVariant.isActive,
      },
    });
  };

  handleButtonSave = () => {
    const {
      form: { kitVariants },
      onChange,
    } = this.props;
    const { formKitPack, editIndex } = this.state;

    const updatedVariants = [...kitVariants];

    updatedVariants[editIndex] = { ...formKitPack };

    onChange('kitVariants', updatedVariants);

    this.setState({
      editIndex: null,
      formKitPack: CommonHelper.objectCloning(initialForm),
    });
  };

  handleButtonCancel = () => {
    const {
      form: { kitVariants },
      onChange,
    } = this.props;
    const { editIndex } = this.state;

    const isNewRow =
      editIndex !== null &&
      !kitVariants[editIndex].title &&
      !kitVariants[editIndex].quota &&
      !kitVariants[editIndex].description;

    if (isNewRow) {
      const updatedVariants = kitVariants.filter((_, index) => index !== editIndex);
      onChange('kitVariants', updatedVariants);
    }

    this.setState({
      editIndex: null,
      formKitPack: CommonHelper.objectCloning(initialForm),
    });
  };

  handleFormChange = (key, value) => {
    const { formKitPack } = this.state;

    this.setState({
      formKitPack: { ...formKitPack, [key]: value },
    });
  };

  renderColumns = () => {
    const { formKitPack, editIndex } = this.state;
    const isFormComplete = formKitPack.title && formKitPack.quota;

    return [
      {
        title: 'No',
        dataIndex: 'id',
        key: 'id',
        render: (text, row, index) => index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        render: (text, row, index) => {
          return editIndex === index ? (
            <TextInput
              placeHolderText="Enter Size"
              currentValue={formKitPack.title}
              size="md"
              onChange={value => this.handleFormChange('title', value, index)}
            />
          ) : (
            row.title
          );
        },
      },
      {
        title: 'Stock',
        dataIndex: 'quota',
        key: 'quota',
        render: (text, row, index) => {
          return editIndex === index ? (
            <TextInputNumber
              placeHolderText="Enter Stock"
              defaultValue={formKitPack.quota}
              size="md"
              onChange={value => this.handleFormChange('quota', value, index)}
            />
          ) : (
            row.quota
          );
        },
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text, row, index) => {
          return editIndex === index ? (
            <TextInput
              placeHolderText="Enter Description"
              currentValue={formKitPack.description}
              size="md"
              onChange={value => this.handleFormChange('description', value, index)}
            />
          ) : (
            row.description
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'isActive',
        key: 'isActive',
        render: (text, row, index) => {
          return editIndex === index ? (
            <Switch
              checked={formKitPack.isActive}
              onChange={checked => this.handleFormChange('isActive', checked)}
            />
          ) : (
            <Switch checked={row.isActive} disabled />
          );
        },
      },
      {
        align: 'center',
        render: (text, row, index) => {
          return editIndex === index ? (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffi-save"
                  type="secondary"
                  size="sm"
                  disabled={!isFormComplete}
                  onClick={this.handleButtonSave}
                />
              </Grid>
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-close"
                  type="negative"
                  size="sm"
                  onClick={this.handleButtonCancel}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container justify="center">
              <Grid item>
                <ButtonIconMain
                  icon="ic-ffo-edit"
                  type="secondary"
                  size="sm"
                  onClick={() => this.handleButtonEdit(index)}
                />
              </Grid>
            </Grid>
          );
        },
      },
    ];
  };

  render() {
    const {
      form: {
        kitImage,
        kitType,
        kitVenue,
        kitDescription,
        kitCollectionStartDate,
        kitCollectionEndDate,
        kitCollectionStartTime,
        kitCollectionEndTime,
        kitVariants,
      },
      validation,
      onChange,
    } = this.props;

    return (
      <div className="flex-column" style={{ gap: '24px' }}>
        <div className="container-step-create-kitpack flex-column mt-24" style={{ gap: '24px' }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="container-filter flex-wrap-unset ph-32 pv-16 title-container"
          >
            <Grid item lg md>
              <label className="text-16 text-black text-semi-bold">Kit Information</label>
            </Grid>
          </Grid>
          <div className="p-32">
            <Grid container spacing={2}>
              <Grid item lg md>
                <div className="container-image-action">
                  <UploadImage
                    defaultValue={kitImage}
                    maxSize={1}
                    validateStatus={!!validation.kitImage}
                    errorMessage={validation.kitImage}
                    allowedFileTypes={['image/jpeg', 'image/png']}
                    onChange={item => onChange('kitImage', item)}
                  />
                </div>
              </Grid>
              <Grid item xl={9} lg={9} md={9}>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Type" isRequired />
                      <TextInput
                        placeHolderText="Enter Type"
                        currentValue={kitType}
                        size="md"
                        isError={!!validation.kitType}
                        errorMessage={validation.kitType}
                        onChange={value => onChange('kitType', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={8} md={8}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Venue" isRequired />
                      <TextInput
                        placeHolderText="Enter Venue"
                        currentValue={kitVenue}
                        size="md"
                        isError={!!validation.kitVenue}
                        errorMessage={validation.kitVenue}
                        onChange={value => onChange('kitVenue', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Description" isRequired />
                      <TextInput
                        placeHolderText="Enter Description"
                        currentValue={kitDescription}
                        size="md"
                        isError={!!validation.kitDescription}
                        errorMessage={validation.kitDescription}
                        onChange={value => onChange('kitDescription', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6}>
                    <Grid container spacing={2}>
                      <Grid item lg md>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="Start Date" isRequired />
                          <PickerInputDate
                            customIcon="ic-ffo-date-pick"
                            dateFormat="dd/MM/yyyy"
                            defaultValue={kitCollectionStartDate}
                            minDate={null}
                            toolbar={false}
                            onChange={value => onChange('kitCollectionStartDate', value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item lg md>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <LabelInput labelText="End Date" isRequired />
                          <PickerInputDate
                            customIcon="ic-ffo-date-pick"
                            dateFormat="dd/MM/yyyy"
                            defaultValue={kitCollectionEndDate}
                            minDate={kitCollectionStartDate}
                            toolbar={false}
                            onChange={value => onChange('kitCollectionEndDate', value)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Grid container spacing={2}>
                      <Grid item lg md>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <FormLabel component="label" className="text-12 field-title">
                            Start Time
                          </FormLabel>
                          <Form.Item>
                            <PickerInputTime
                              customIcon="ic-ffo-timer"
                              defaultValue={kitCollectionStartTime}
                              onChange={value => onChange('kitCollectionStartTime', value)}
                            />
                          </Form.Item>
                        </FormControl>
                      </Grid>
                      <Grid item lg md>
                        <FormControl component="fieldset" fullWidth margin="normal">
                          <FormLabel component="label" className="text-12 field-title">
                            End Time
                          </FormLabel>
                          <Form.Item>
                            <PickerInputTime
                              customIcon="ic-ffo-timer"
                              defaultValue={kitCollectionEndTime}
                              onChange={value => onChange('kitCollectionEndTime', value)}
                            />
                          </Form.Item>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="container-step-create-kitpack flex-column mt-24" style={{ gap: '24px' }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="container-filter flex-wrap-unset ph-32 pv-16 title-container"
          >
            <Grid item lg md>
              <label className="text-16 text-black text-semi-bold">Kit Variant</label>
            </Grid>
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                labelText="Add Variant"
                onClick={this.handleAddVariant}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
                disabled={this.state.editIndex !== null}
              />
            </Grid>
          </Grid>
          <div className="ph-32 pb-32">
            <Table
              columns={this.renderColumns()}
              rowKey={record => record.id}
              dataSource={kitVariants}
            />
          </div>
        </div>
        {validation.kitVariants ? (
          <label className="text-12 text-regular ml-16" style={{ color: '#f44336' }}>
            {validation.kitVariants}
          </label>
        ) : null}
      </div>
    );
  }
}

StepCreateKitPack.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateKitPack));
