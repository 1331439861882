/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  NavigationStep,
} from '../../../components/Index';
import { SkeletonDetails } from '../../../components/skeleton/Index';
import { ButtonActionFooter } from './components/Index';
import {
  StepCreateDetails,
  StepCreateTimeline,
  StepCreateRules,
  StepCreateWaiver,
} from './views/Index';
// Api
import {
  getEventDetails,
  createEventContent,
  getEventContent,
} from '../../../services/api/EventApi';
// Helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
} from '../../../helpers/Index';
// Style
import './EventCMSAddStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  eventId: null,
  banner: null,
  logo: null,
  runningText: { lists: [{ icon: null, text: '' }] },
  description: {
    title: '',
    highlightedText: '',
    description: '',
    backgroundImage: null,
    color: '#1677ff',
  },
  partners: { lists: [{ title: '', logo: [] }] },
  highlights: {
    title: '',
    backgroundColor: '#1677ff',
    lists: [{ icon: null, title: '', description: '' }],
  },
  timeline: {
    image: null,
    backgroundColor: '#1677ff',
    color: '#1677ff',
    lists: [{ title: '', date: '' }],
  },
  location: {
    backgroundImage: null,
    title: '',
    description: '',
    lists: [{ locationPhoto: null, icon: null, title: '', date: '' }],
  },
  additionalInformation: {
    backgroundImage: null,
    lists: [{ image: null, title: '', description: '', text: '', link: '' }],
  },
  rules: { title: '', backgroundColor: '#1677ff', color: '#1677ff', lists: [{ description: '' }] },
  benefits: {
    title: '',
    backgroundColor: '#1677ff',
    color: '#1677ff',
    lists: [{ description: '' }],
  },
  faq: {
    backgroundColor: '#1677ff',
    lists: [{ question: '', answer: '' }],
  },
  socialMedia: { lists: [{ type: 'instagram', name: '', link: '' }] },
  waiver: {
    title: '',
    lists: [{ description: '' }],
    tncId: '',
    tncEng: '',
  },
};

const validationRules = {
  0: [
    'banner',
    'logo',
    'runningText.lists',
    'description.title',
    'description.highlightedText',
    'description.description',
    'description.backgroundImage',
    'partners.lists',
    'highlights.title',
    'highlights.lists',
  ],
  1: [
    'timeline.image',
    'timeline.lists',
    'location.backgroundImage',
    'location.title',
    'location.description',
    'location.lists',
    'additionalInformation.backgroundImage',
    'additionalInformation.lists',
  ],
  2: [
    'rules.title',
    'rules.lists',
    'benefits.title',
    'benefits.lists',
    'faq.lists',
    'socialMedia.lists',
  ],
  3: ['waiver.title', 'waiver.lists', 'waiver.tncId', 'waiver.tncEng'],
};

const optionalFields = ['link'];

const fieldDisplayNames = {
  banner: 'Banner Hero',
  logo: 'Event Logo',
  'runningText.lists': 'Running Text',
  'description.title': 'Event Title',
  'description.highlightedText': 'Highlighted Text',
  'description.description': 'Description',
  'description.backgroundImage': 'Background Image',
  'partners.lists': 'Event Partners',
  'highlights.title': 'Highlights Title',
  'highlights.lists': 'Highlights',
  'timeline.image': 'Timeline Image',
  'timeline.lists': 'Timeline',
  'location.backgroundImage': 'Location Background Image',
  'location.title': 'Location Title',
  'location.description': 'Location Description',
  'location.lists': 'Location',
  'additionalInformation.backgroundImage': 'Additional Information Background Image',
  'additionalInformation.lists': 'Additional Information',
  'socialMedia.lists': 'Social Media',
  'rules.title': 'Rules Title',
  'rules.lists': 'Rules',
  'benefits.title': 'Benefits Title',
  'benefits.lists': 'Rules',
  'faq.lists': 'FAQ',
  'waiver.title': 'Waiver Title',
  'waiver.lists': 'Waiver',
  'waiver.tncId': 'Terms and Conditions (Indonesia)',
  'waiver.tncEng': 'Terms and Conditions (English)',
};

class EventCMSAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Event,
      PermissionPage.EventList,
      PermissionAccess.Add,
      PermissionAccess.Update,
    );

    this.state = {
      current: 0,
      form: CommonHelper.objectCloning(initialForm),
      validationErrors: {},
      isLoading: false,
      eventDetails: {},
      stepList: [
        { key: 'create_information', name: 'Event Information' },
        { key: 'create_timeline', name: 'Event Timeline' },
        { key: 'create_rules_faq', name: 'Rules and FAQ' },
        { key: 'create_waiver_agreement', name: 'Waiver and Agreement' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Event - Create Event CMS',
        breadcrumbs: 'CMS',
        card: 'CMS',
      },
    };
  }

  componentDidMount() {
    const { form, pagesTitle } = this.state;
    const {
      match: { params },
      history,
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    if (updateExitingForm.eventId) {
      this.setState(
        {
          form: updateExitingForm,
          pagesTitle: {
            ...pagesTitle,
            helmet: 'FITCO | Event - Edit Event CMS',
            breadcrumbs: 'Edit Event',
            card: 'Edit Event CMS',
          },
        },
        () => {
          this.getEventDetails();
          this.getEventContentDetails();
        },
      );
    }

    // Handle back button on browser
    this.handleBackButton = event => {
      event.preventDefault();
      if (window.location.pathname.includes('/event/edit')) {
        history.replace('/event/list');
      }
    };
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  getEventDetails = async () => {
    const { eventDetails } = this.props;
    const { form } = this.state;

    try {
      const response = await eventDetails(form.eventId);
      const eventData = response.data;

      this.setState({ eventDetails: { ...eventData } });
    } catch (error) {
      const serverMessage = error.data;
      const validationStatus = error.status === HttpStatusCode.InternalServerError;
      this.processMessage(
        validationStatus ? serverMessage.message : serverMessage.messages,
        'error',
      );
    }
  };

  getEventContentDetails = async () => {
    const { eventContentDetail } = this.props;
    const { form } = this.state;

    try {
      const response = await eventContentDetail(form.eventId);
      const eventData = response.data;
      const formData = this.mapApiResponseToInitialForm(eventData);
      this.setState({ form: { ...form, ...formData } });
    } catch (error) {
      const serverMessage = error.data;
      const validationStatus = error.status === HttpStatusCode.InternalServerError;
      this.processMessage(
        validationStatus ? serverMessage.message : serverMessage.messages,
        'error',
      );
    }
  };

  submitCreateEvent = async () => {
    const { setCreateEventContent, history } = this.props;
    const { form } = this.state;

    const payload = this.transformPayload(form);
    try {
      this.setState({ isLoading: true });

      const response = await setCreateEventContent(payload, form.eventId);
      this.processMessage(response.messages, response.status);
      if (response.code === HttpStatusCode.Success) {
        setTimeout(async () => {
          await history.push('/event/list');
        }, 2000);
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.processMessage(error.data.messages, 'error');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  transformPayload = form => {
    const payload = {
      logo: form.logo || '',
      banner_background: form.banner || '',
      running_texts: form.runningText.lists.map((item, index) => ({
        order: index + 1,
        icon: item.icon || null,
        text: item.text || '',
      })),
      descriptions: {
        title: form.description.title || '',
        description: form.description.description || '',
        background_image: form.description.backgroundImage,
        title_color: form.description.color,
        description_color: null,
        background_color: null,
        additional_information: {
          highlighted_text: form.description.highlightedText || '',
        },
      },
      partners: form.partners.lists.map(partner => ({
        title: partner.title || '',
        description: null,
        background_image: null,
        title_color: null,
        description_color: null,
        background_color: null,
        images: partner.logo,
      })),
      usp: {
        title: form.highlights.title || '',
        description: null,
        background_image: null,
        title_color: null,
        description_color: null,
        background_color: form.highlights.backgroundColor,
        contents: form.highlights.lists.map((item, index) => ({
          order: index + 1,
          title: item.title || '',
          description: item.description || '',
          icon: item.icon || '',
        })),
        additional_information: null,
      },
      timeline: {
        title: 'Timeline title',
        description: null,
        background_image: null,
        title_color: null,
        description_color: null,
        background_color: form.timeline.backgroundColor,
        contents: form.timeline.lists.map((item, index) => ({
          order: index + 1,
          date: item.date || '',
          title: item.title || '',
        })),
        additional_information: {
          title_image: form.timeline.image || '',
          separator_color: form.timeline.color,
        },
      },
      locations: {
        title: form.location.title || '',
        description: form.location.description || '',
        background_image: form.location.backgroundImage || '',
        title_color: null,
        description_color: null,
        background_color: null,
        contents: form.location.lists.map(item => ({
          name: item.title || '',
          photo: item.locationPhoto || '',
          icon: item.icon || null,
          date: item.date || '',
        })),
      },
      activities: {
        title: 'Activities title',
        description: null,
        background_image: form.additionalInformation.backgroundImage || '',
        title_color: null,
        description_color: null,
        background_color: null,
        contents: form.additionalInformation.lists.map((item, index) => ({
          order: index + 1,
          image: item.image || '',
          title: item.title || '',
          description: item.description || '',
          date: item.text || '',
          link: item.link || '',
        })),
        additional_information: null,
      },
      rules: {
        title: form.rules.title || '',
        description: null,
        background_image: null,
        title_color: form.rules.color,
        description_color: null,
        background_color: form.rules.backgroundColor,
        contents: form.rules.lists.map((item, index) => ({
          order: index + 1,
          description: item.description || '',
        })),
        additional_information: null,
      },
      benefits: {
        title: form.benefits.title || '',
        description: null,
        background_image: null,
        title_color: form.benefits.color,
        description_color: null,
        background_color: form.benefits.backgroundColor,
        contents: form.benefits.lists.map((item, index) => ({
          order: index + 1,
          description: item.description || '',
        })),
      },
      faq: {
        title: 'FAQ Title',
        description: null,
        background_image: null,
        title_color: null,
        description_color: null,
        background_color: form.faq.backgroundColor,
        contents: form.faq.lists.map((item, index) => ({
          order: index + 1,
          title: item.question || '',
          description: item.answer || '',
        })),
        additional_information: null,
      },
      social_media: form.socialMedia.lists.map(item => ({
        type: item.type || '',
        name: item.name || '',
        link: item.link || '',
      })),
      terms_and_conditions: {
        title: form.waiver.title || '',
        description: null,
        background_image: null,
        title_color: null,
        description_color: null,
        background_color: null,
        waivers: form.waiver.lists.map(item => item.description || ''),
        ver_indonesian: form.waiver.tncId || '',
        ver_english: form.waiver.tncEng || '',
      },
    };

    return payload;
  };

  mapApiResponseToInitialForm = apiResponse => {
    if (apiResponse) {
      return {
        logo: apiResponse.logo || null,
        banner: apiResponse.banner_background || null,
        runningText: {
          lists: apiResponse.running_texts.map(item => ({
            icon: item.icon || null,
            text: item.text || '',
          })),
        },
        description: {
          title: apiResponse.descriptions ? apiResponse.descriptions.title : '',
          highlightedText: apiResponse.descriptions
            ? apiResponse.descriptions.additional_information.highlighted_text
            : '',
          description: apiResponse.descriptions ? apiResponse.descriptions.description : '',
          backgroundImage: apiResponse.descriptions
            ? apiResponse.descriptions.background_image
            : null,
          color: apiResponse.descriptions ? apiResponse.descriptions.title_color : '#1677ff',
        },
        partners: {
          lists: apiResponse.partners.map(partner => ({
            title: partner.title || '',
            logo: partner.contents || [],
          })),
        },
        highlights: {
          title: apiResponse.usp.title || '',
          backgroundColor: apiResponse.usp.background_color || '#1677ff',
          lists: apiResponse.usp.contents.map(content => ({
            icon: content.icon || null,
            title: content.title || '',
            description: content.description || '',
          })),
        },
        timeline: {
          image:
            (apiResponse.timeline.additional_information &&
              apiResponse.timeline.additional_information.title_image) ||
            null,
          backgroundColor: apiResponse.timeline.background_color || '#1677ff',
          color:
            (apiResponse.timeline.additional_information &&
              apiResponse.timeline.additional_information.separator_color) ||
            '#1677ff',
          lists: apiResponse.timeline.contents.map(item => ({
            title: item.title || '',
            date: item.date || currentDate,
          })),
        },
        location: {
          backgroundImage: apiResponse.locations.background_image || null,
          title: apiResponse.locations.title || '',
          description: apiResponse.locations.description || '',
          lists: apiResponse.locations.contents.map(location => ({
            locationPhoto: location.photo || null,
            icon: location.icon || null,
            title: location.name || '',
            date: location.date || '',
          })),
        },
        additionalInformation: {
          backgroundImage: apiResponse.activities.background_image || null,
          lists: apiResponse.activities.contents.map(activity => ({
            image: activity.image || null,
            title: activity.title || '',
            description: activity.description || '',
            text: activity.date || '',
            link: activity.link || '',
          })),
        },
        rules: {
          title: apiResponse.rules.title || '',
          backgroundColor: apiResponse.rules.background_color || '#1677ff',
          color: apiResponse.rules.title_color || '#1677ff',
          lists: apiResponse.rules.contents.map(rule => ({
            description: rule.description || '',
          })),
        },
        benefits: {
          title: apiResponse.benefits.title || '',
          backgroundColor: apiResponse.benefits.background_color || '#1677ff',
          color: apiResponse.benefits.title_color || '#1677ff',
          lists: apiResponse.benefits.contents.map(benefit => ({
            description: benefit.description || '',
          })),
        },
        faq: {
          backgroundColor: apiResponse.faq.background_color || '#1677ff',
          lists: apiResponse.faq.contents.map(item => ({
            question: item.title || '',
            answer: item.description || '',
          })),
        },
        socialMedia: {
          lists: apiResponse.social_media.map(media => ({
            type: media.type || 'instagram',
            name: media.name || '',
            link: media.link || '',
          })),
        },
        waiver: {
          title: apiResponse.terms_and_conditions.title || '',
          lists: apiResponse.terms_and_conditions.contents.length
            ? JSON.parse(apiResponse.terms_and_conditions.contents[0].waivers || '[]').map(
                desc => ({
                  description: desc || '',
                }),
              )
            : [],
          tncId: apiResponse.terms_and_conditions.contents.length
            ? apiResponse.terms_and_conditions.contents[0].ver_indonesian
            : '',
          tncEng: apiResponse.terms_and_conditions.contents.length
            ? apiResponse.terms_and_conditions.contents[0].ver_english
            : '',
        },
      };
    }

    return {};
  };

  handleFormChange = (path, value) => {
    this.setState(prevState => {
      const updatedForm = { ...prevState.form };
      const keys = path.replace(/\[(\d+)\]/g, '.$1').split('.');

      keys.reduce((pointer, key, index) => {
        if (index === keys.length - 1) {
          pointer[key] = value;
        } else {
          if (!pointer[key]) {
            pointer[key] = isNaN(keys[index + 1]) ? {} : [];
          }
          return pointer[key];
        }
      }, updatedForm);

      return { form: updatedForm };
    });
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/event/list');
  };

  handleButtonClickSubmit = () => {
    if (this.validateCurrentStep()) {
      this.submitCreateEvent();
    }
  };

  handleButtonClickNext = () => {
    if (this.validateCurrentStep()) {
      if (this.state.current === 3) {
        this.submitCreateEvent();
        return;
      }
      this.setState(prevState => ({ current: prevState.current + 1 }));
    } else {
      window.scrollTo(0, 0);
    }
  };

  handleButtonClickPrev = () => {
    const { current } = this.state;
    this.setState({ current: current - 1 });
  };

  validateCurrentStep = () => {
    const { current, form } = this.state;
    const errors = {};
    const requiredFields = validationRules[current] || [];

    const prettifyFieldName = str => {
      return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/_/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();
    };

    const formatDisplayName = str => {
      const exceptions = ['FAQ'];
      if (exceptions.includes(str.toUpperCase())) {
        return str.toUpperCase();
      }
      return prettifyFieldName(str.charAt(0).toUpperCase() + str.slice(1));
    };

    requiredFields.forEach(field => {
      const keys = field.split('.');
      const value = keys.reduce((acc, key) => (acc ? acc[key] : undefined), form);

      if (field.endsWith('.lists')) {
        const sectionKey = field.replace('.lists', '');
        const section = form[sectionKey];
        if (section && Array.isArray(section.lists)) {
          section.lists.forEach((listItem, index) => {
            Object.keys(listItem).forEach(key => {
              const itemValue = listItem[key];

              if (
                sectionKey === 'additionalInformation' &&
                key === 'link' &&
                optionalFields.includes(key)
              ) {
                return;
              }

              if (
                itemValue === null ||
                itemValue === undefined ||
                (typeof itemValue === 'string' && itemValue.trim() === '') ||
                (Array.isArray(itemValue) && itemValue.length === 0)
              ) {
                const sectionDisplayName = formatDisplayName(
                  fieldDisplayNames[`${sectionKey}.${key}`] ||
                    `${prettifyFieldName(sectionKey)} ${key}`,
                );
                errors[
                  `${sectionKey}.lists[${index}].${key}`
                ] = `${sectionDisplayName} is required`;
              }
            });
          });
        }
      } else if (
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      ) {
        const displayName = formatDisplayName(fieldDisplayNames[field] || prettifyFieldName(field));
        errors[field] = `${displayName} is required`;
      }
    });

    this.setState({ validationErrors: errors });

    return Object.keys(errors).length === 0;
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const { stepList, current, form, validationErrors } = this.state;
    const { handleFormChange } = this;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateTimeline
              initialForm={initialForm}
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;
      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateRules
              initialForm={initialForm}
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;
      case stepList[3].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateWaiver
              initialForm={initialForm}
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;

      default:
        renderElement = (
          <Grid item lg md>
            <StepCreateDetails
              initialForm={initialForm}
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
    }

    return renderElement;
  }

  render() {
    const {
      eventData: { fetching },
    } = this.props;
    const { toastInformation, current, stepList, pagesTitle, isLoading, eventDetails } = this.state;
    const prevUrl = '/event/list';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetails />
      </div>
    );

    if (!fetching) {
      renderElement = (
        <div>
          <Helmet title={pagesTitle.helmet} />
          <div className="container-page-event-cms-add scroll-container-invisible">
            <div className={`container-page-scrolling-area include-footer`}>
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-coupon container-icon-prefix size-16" />
                        Event
                      </Link>
                      <label className="text-12" color="inherit">
                        {pagesTitle.breadcrumbs}
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <Grid container direction="column" className="flex-wrap-unset">
                    <Grid item lg md className="flex-column flex-wrap-unset mb-32">
                      <Grid
                        container
                        direction="column"
                        className="flex-wrap-unset event-details-information mb-32"
                      >
                        <Grid item lg={12} md={12}>
                          <div
                            style={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
                              marginBottom: '20px',
                              paddingBottom: '20px',
                            }}
                          >
                            <label className="text-bold text-20 text-mine-shaft">
                              {eventDetails.name}
                            </label>
                          </div>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item lg={3} md={3}>
                            <Grid container direction="column">
                              <label className="text-14 text-medium text-rolling-stone mb-8">
                                Category
                              </label>
                              <label className="text-14 text-semi-bold text-black">
                                {eventDetails.event_category}
                              </label>
                            </Grid>
                          </Grid>
                          <Grid item lg={3} md={3}>
                            <Grid container direction="column">
                              <label className="text-14 text-medium text-rolling-stone mb-8">
                                Venue
                              </label>
                              <label className="text-14 text-semi-bold text-black">
                                {eventDetails.address}
                              </label>
                            </Grid>
                          </Grid>
                          <Grid item lg={3} md={3}>
                            <Grid container direction="column">
                              <label className="text-14 text-medium text-rolling-stone mb-8">
                                Start Date
                              </label>
                              <label className="text-14 text-semi-bold text-black">
                                {CommonHelper.dateTimeParseNewFormat(
                                  eventDetails.start_date,
                                  'DD MMMM YYYY',
                                )}
                              </label>
                            </Grid>
                          </Grid>
                          <Grid item lg={3} md={3}>
                            <Grid container direction="column">
                              <label className="text-14 text-medium text-rolling-stone mb-8">
                                Start Time
                              </label>
                              <label className="text-14 text-semi-bold text-black">
                                {`${eventDetails.start_time} WIB`}
                              </label>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <NavigationStep currentValue={current} arrayLabel={stepList} />
                      <div className="mt-32">{this.renderActiveContent()}</div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <div className="container-page-promo-code-create-floating">
            <ButtonActionFooter
              currentValue={current}
              totalStepper={stepList.length}
              isLoading={isLoading}
              visiblePrev={current !== 0}
              visibleSubmit={current === stepList.length - 1}
              onCancelClick={this.handleButtonClickCancel}
              onSubmitClick={this.handleButtonClickSubmit}
              onButtonClickPrev={this.handleButtonClickPrev}
              onButtonClickNext={this.handleButtonClickNext}
            />
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  eventDetails: eventId => getEventDetails(dispatch, eventId),
  eventContentDetail: eventId => getEventContent(dispatch, eventId),
  setCreateEventContent: (params, eventId) => createEventContent(dispatch, params, eventId),
});

const mapStateToProps = ({ usersReducer, eventData }) => ({
  usersReducer,
  eventData,
});

EventCMSAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  eventContentDetail: PropTypes.func,
  eventData: PropTypes.object,
  eventDetails: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  setCreateEventContent: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(EventCMSAdd));
