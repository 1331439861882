/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Breadcrumbs, Link } from '@material-ui/core';
import { compose } from 'redux';
// Component
import {
  SnackBarSimple,
  AuthenticationAccessPages,
  NavigationStep,
  ButtonMain,
} from '../../../components/Index';
import { SkeletonDetails } from '../../../components/skeleton/Index';
import {
  StepCreateEvent,
  StepCreateTicket,
  StepCreateQuestionnaire,
  StepCreateKitPack,
  StepCreateAddOn,
} from './views/Index';
// Api
import { getEventCategory, createEvent, getEventDetails } from '../../../services/api/EventApi';
// Helper
import {
  CommonHelper,
  PermissionModule,
  PermissionPage,
  PermissionAccess,
  HttpStatusCode,
} from '../../../helpers/Index';
// Style
import './EventAddStyle.scss';

const currentDate = CommonHelper.currentDate('YYYY-MM-DD');
const endDateValue = CommonHelper.getEndDateMonth(currentDate);
const currentTime = CommonHelper.currentDate('HH:mm');

const optionToast = {
  vertical: 'top',
  horizontal: 'right',
};

const initialForm = {
  eventId: null,
  productId: null,
  banner: null,
  name: '',
  category: null,
  status: null,
  description: '',
  venue: '',
  startDate: currentDate,
  endDate: endDateValue,
  startTime: '00:00',
  endTime: CommonHelper.objectCloning(currentTime),
  tickets: [],
  questionnaires: [],
  kitImage: null,
  kitType: null,
  kitVenue: '',
  kitDescription: '',
  kitCollectionStartDate: currentDate,
  kitCollectionEndDate: currentDate,
  kitCollectionStartTime: '00:00',
  kitCollectionEndTime: CommonHelper.objectCloning(currentTime),
  kitVariants: [],
  addOn: [],
  hasMasterCategory: false,
  masterCategory: '',
  isGenderSpecific: false,
  genderSpecific: '',
};

const validationRules = {
  0: [
    'banner',
    'name',
    'category',
    'status',
    'description',
    'venue',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
  ],
  1: ['tickets'],
  2: ['questionnaires'],
  3: [
    'kitImage',
    'kitType',
    'kitVenue',
    'kitDescription',
    'kitCollectionStartDate',
    'kitCollectionEndDate',
    'kitCollectionStartTime',
    'kitCollectionEndTime',
    'kitVariants',
  ],
};

class EventAdd extends React.Component {
  constructor(props) {
    super(props);

    props.checkUserAccessPermission(
      PermissionModule.Event,
      PermissionPage.EventList,
      PermissionAccess.Add,
      PermissionAccess.Update,
    );

    this.state = {
      current: 0,
      form: CommonHelper.objectCloning(initialForm),
      validationErrors: {},
      isLoading: false,
      stepList: [
        { key: 'event_information', name: 'Event Information' },
        { key: 'event_ticket', name: 'Event Ticket' },
        { key: 'questionnaire', name: 'Questionnaire' },
        { key: 'event_kit', name: 'Event Kit' },
        { key: 'product_add_on', name: 'Product Add-on' },
      ],
      toastInformation: {
        isOpen: false,
        message: '',
        snackbarType: 'warning',
      },
      pagesTitle: {
        helmet: 'FITCO | Event - Create Event',
        breadcrumbs: 'Create Event',
        card: 'Create Event',
      },
    };
  }

  componentDidMount() {
    const { form, pagesTitle } = this.state;
    const {
      match: { params },
      history,
    } = this.props;

    const ifExistParamObject = params.param_object
      ? CommonHelper.decryptObject(params.param_object)
      : {};

    const updateExitingForm = {
      ...CommonHelper.objectCloning(form),
      ...ifExistParamObject,
    };

    if (updateExitingForm.eventId) {
      this.setState(
        {
          form: updateExitingForm,
          pagesTitle: {
            ...pagesTitle,
            helmet: 'FITCO | Event - Edit Event',
            breadcrumbs: 'Edit Event',
            card: 'Edit Event',
          },
        },
        () => {
          this.getEventDetails();
        },
      );
    }

    this.fetchEventCategories();

    // Handle back button on browser
    this.handleBackButton = event => {
      event.preventDefault();
      if (window.location.pathname.includes('/event/edit')) {
        history.replace('/event/list');
      }
    };
    window.addEventListener('popstate', this.handleBackButton);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBackButton);
  }

  getEventDetails = async () => {
    const { eventDetails } = this.props;
    const { form } = this.state;

    try {
      const response = await eventDetails(form.eventId);
      const eventData = response.data;

      const updatedForm = {
        eventId: eventData.event_id,
        productId: eventData.product_id,
        banner: eventData.images.length ? eventData.images[0].image_url : null,
        name: eventData.name,
        category: eventData.category,
        status: eventData.status,
        description: eventData.description,
        venue: eventData.address,
        startDate: eventData.start_date,
        endDate: eventData.end_date,
        startTime: eventData.start_time,
        endTime: eventData.end_time,
        tickets: eventData.tickets.map(ticket => ({
          product_id: ticket.product_id,
          banner: ticket.banner_image,
          name: ticket.name,
          quota: ticket.quota ? ticket.quota.toString() : '',
          remainingQuota: ticket.remaining_quota ? ticket.remaining_quota.toString() : '',
          minAge: ticket.min_age ? ticket.min_age.toString() : '',
          hasKit: ticket.has_kit,
          hasPriceTiering: ticket.special_price !== null,
          price: ticket.normal_price || '',
          indonesianPrice: ticket.special_price || '',
          foreignerPrice: ticket.normal_price || '',
          status: ticket.status,
          description: ticket.description || '',
          descImage: ticket.detail_image,
          raceOpen: ticket.race_open_time,
          cutOffTime: ticket.cut_off_time,
        })),
        kitImage: eventData.kit_image,
        kitType: eventData.kit_type || '',
        kitVenue: eventData.kit_collection_venue || '',
        kitDescription: eventData.kit_description || '',
        kitCollectionStartDate: eventData.kit_collection_start_date || '',
        kitCollectionEndDate: eventData.kit_collection_end_date || '',
        kitCollectionStartTime: eventData.kit_collection_start_time || '',
        kitCollectionEndTime: eventData.kit_collection_end_time || '',
        kitVariants: eventData.kits
          ? eventData.kits.map(variant => ({
              title: variant.title,
              quota: variant.quota ? variant.quota.toString() : '',
              description: variant.description || '',
              isActive: variant.is_active || false,
              eventKitId: variant.event_kit_id,
            }))
          : [],
        addOn: eventData.add_on.map(additional => ({
          ...additional,
          productId: additional.product_id,
        })),
        questionnaires: eventData.questionnaires,
        hasMasterCategory: Boolean(eventData.master_category),
        masterCategory: eventData.master_category,
        isGenderSpecific: Boolean(eventData.gender_specific),
        genderSpecific: eventData.gender_specific === '1' ? 'male' : 'female',
      };

      this.setState({ form: updatedForm });
    } catch (error) {
      const serverMessage = error.data;
      const validationStatus = error.status === HttpStatusCode.InternalServerError;
      this.processMessage(
        validationStatus ? serverMessage.message : serverMessage.messages,
        'error',
      );
    }
  };

  fetchEventCategories = async () => {
    const { getCategory } = this.props;
    try {
      await getCategory();
    } catch (error) {}
  };

  submitCreateEvent = async () => {
    const { setCreateEvent, history } = this.props;
    const { form } = this.state;

    const payload = this.transformPayload(form);
    try {
      this.setState({ isLoading: true });

      const response = await setCreateEvent(payload);
      this.processMessage(response.messages, response.status);
      if (response.code === HttpStatusCode.Success) {
        setTimeout(async () => {
          await history.push('/event/list');
        }, 2000);
      }
    } catch (error) {
      this.processMessage(error.data.messages, 'error');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  transformPayload = form => {
    const images = form.banner ? [{ image: form.banner, sort: 1, status: 10 }] : [];

    const tickets = form.tickets.map(ticket => ({
      product_id: ticket.product_id,
      name: ticket.name,
      quota: ticket.quota,
      point_price: ticket.hasPriceTiering ? ticket.foreignerPrice : ticket.price,
      normal_price: ticket.hasPriceTiering ? ticket.foreignerPrice : ticket.price,
      special_price: ticket.hasPriceTiering ? ticket.indonesianPrice : null,
      description: ticket.description,
      status: ticket.status,
      min_age: ticket.minAge ? ticket.minAge : null,
      has_kit: ticket.hasKit,
      banner_image: ticket.banner,
      detail_image: ticket.descImage,
      race_open_time: ticket.raceOpen,
      cut_off_time: ticket.cutOffTime,
    }));

    const addOn = form.addOn.map(item => ({
      product_id: item.productId,
      price: item.price,
    }));

    const questionnaires = form.questionnaires.map(item => ({
      question: item.question,
      type: item.type,
      is_required: item.is_required,
      has_notes: item.has_notes,
    }));

    const payload = {
      product_id: form.productId,
      name: form.name,
      description: form.description,
      status: form.status,
      category: form.category,
      address: form.venue,
      start_date: form.startDate,
      start_time: form.startTime,
      end_date: form.endDate,
      end_time: form.endTime,
      kit_type: form.kitType,
      kit_collection_venue: form.kitVenue,
      kit_description: form.kitDescription,
      kit_collection_start_date: form.kitCollectionStartDate,
      kit_collection_end_date: form.kitCollectionEndDate,
      kit_collection_start_time: form.kitCollectionStartTime,
      kit_collection_end_time: form.kitCollectionEndTime,
      kit_image: form.kitImage,
      images,
      tickets,
      kits: form.kitVariants.map(variant => ({
        event_kit_id: variant.eventKitId || null,
        title: variant.title,
        description: variant.description,
        quota: variant.quota,
      })),
      add_on: addOn,
      questionnaires,
      master_category: form.masterCategory,
      gender_specific: form.genderSpecific,
    };

    return payload;
  };

  handleFormChange = (key, value) => {
    const { form } = this.state;

    if (key === 'hasMasterCategory' && !value) {
      this.setState({
        form: { ...form, [key]: value, masterCategory: '' },
      });
    } else if (key === 'isGenderSpecific' && !value) {
      this.setState({
        form: { ...form, [key]: value, genderSpecific: '' },
      });
    } else {
      this.setState({
        form: { ...form, [key]: value },
      });
    }
  };

  handleClick = (event, value) => {
    const { history } = this.props;
    event.preventDefault();
    history.push(value);
  };

  handleButtonClickCancel = () => {
    const { history } = this.props;
    history.push('/event/list');
  };

  handleButtonClickNext = () => {
    if (this.validateCurrentStep()) {
      if (this.state.current === 4) {
        this.submitCreateEvent();
        return;
      }
      this.setState(prevState => ({ current: prevState.current + 1 }));
    } else {
      window.scrollTo(0, 0);
    }
  };

  handleButtonClickPrev = () => {
    const { current } = this.state;
    this.setState({ current: current - 1 });
  };

  validateCurrentStep = () => {
    const { current, form } = this.state;
    const errors = {};
    const requiredFields = validationRules[current] || [];

    requiredFields.forEach(field => {
      const strippedFieldName = field.startsWith('kit') ? field.replace('kit', '') : field;
      const displayName = strippedFieldName.charAt(0).toUpperCase() + strippedFieldName.slice(1);
      const value = this.state.form[field];

      if (
        value === null ||
        value === undefined ||
        (typeof value === 'string' && value.trim() === '') ||
        (Array.isArray(value) && value.length === 0)
      ) {
        errors[field] = `${displayName} is required`;
      }
    });

    if (form.hasMasterCategory && !form.masterCategory) {
      errors.masterCategory = 'Master Category is required';
    }

    if (form.isGenderSpecific && !form.genderSpecific) {
      errors.genderSpecific = 'Gender Specific is required';
    }

    this.setState({ validationErrors: errors });
    return Object.keys(errors).length === 0;
  };

  handleCloseToast = () => {
    const { toastInformation } = this.state;
    this.setState({ toastInformation: { ...toastInformation, isOpen: false } });
  };

  processMessage(messages, type) {
    const convertedMessage = CommonHelper.generateMessage(messages);

    const paramInformation = {
      isOpen: true,
      message: convertedMessage,
      snackbarType: type,
    };

    this.setState({
      toastInformation: paramInformation,
    });
  }

  renderActiveContent() {
    const {
      eventData: { eventCategories },
    } = this.props;
    const { stepList, current, form, validationErrors } = this.state;
    const { handleFormChange } = this;
    let renderElement = null;

    switch (stepList[current].key) {
      case stepList[1].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateTicket
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;
      case stepList[2].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateQuestionnaire
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;
      case stepList[3].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateKitPack
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;
      case stepList[4].key:
        renderElement = (
          <Grid item lg md>
            <StepCreateAddOn
              form={form}
              validation={validationErrors}
              onChange={handleFormChange}
            />
          </Grid>
        );
        break;

      default:
        renderElement = (
          <Grid item lg md>
            <StepCreateEvent
              form={form}
              validation={validationErrors}
              categories={eventCategories}
              onChange={handleFormChange}
            />
          </Grid>
        );
    }

    return renderElement;
  }

  render() {
    const {
      eventData: { fetching },
    } = this.props;
    const { toastInformation, current, stepList, pagesTitle, isLoading } = this.state;
    const prevUrl = '/event/list';

    let renderElement = (
      <div className="container-page-scrolling-area">
        <SkeletonDetails />
      </div>
    );

    if (!fetching) {
      renderElement = (
        <div>
          <Helmet title={pagesTitle.helmet} />
          <div className="container-page-event-add scroll-container">
            <div className="container-page-scrolling-area">
              <Grid container direction="column" className="flex-wrap-unset">
                <Grid item lg md className="section-page-header">
                  <div className="breadcrumbs-section">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        className="text-12"
                        color="inherit"
                        href={prevUrl}
                        onClick={event => {
                          this.handleClick(event, prevUrl);
                        }}
                      >
                        <i className="ic-ffo-coupon container-icon-prefix size-16" />
                        Event
                      </Link>
                      <label className="text-12" color="inherit">
                        {pagesTitle.breadcrumbs}
                      </label>
                    </Breadcrumbs>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-body">
                  <div className="container-main-card flex-column p-32">
                    <label className="text-16 text-bold text-rolling-stone mb-32">
                      {pagesTitle.card}
                    </label>
                    <NavigationStep currentValue={current} arrayLabel={stepList} />
                    <div className="mt-32">{this.renderActiveContent()}</div>
                    <Grid item lg={12} md={12} className="mt-36">
                      <Grid container justify="flex-end">
                        <Grid item lg={5} md={6}>
                          <Grid container justify="flex-end">
                            <Grid item lg={4} md={4} className="pl-8">
                              <ButtonMain
                                type="negative"
                                size="xl"
                                labelText="Cancel"
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={this.handleButtonClickCancel}
                              />
                            </Grid>
                            {current !== 0 ? (
                              <Grid item lg={4} md={4} className="pl-8">
                                <ButtonMain
                                  type="ghost"
                                  size="xl"
                                  labelText="Prev"
                                  disabled={isLoading}
                                  isLoading={isLoading}
                                  onClick={this.handleButtonClickPrev}
                                />
                              </Grid>
                            ) : null}
                            <Grid item lg={4} md={4} className="pl-8">
                              <ButtonMain
                                type="primary"
                                size="xl"
                                labelText={current === 4 ? 'Save' : 'Next'}
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={this.handleButtonClickNext}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item lg md className="section-page-footer" />
              </Grid>
            </div>
          </div>
          <SnackBarSimple
            open={toastInformation.isOpen}
            durationHide={2000}
            message={toastInformation.message}
            onClickClose={this.handleCloseToast}
            snackbarType={toastInformation.snackbarType}
            anchor={optionToast}
          />
        </div>
      );
    }

    return renderElement;
  }
}

const mapDispatchToProps = dispatch => ({
  getCategory: () => getEventCategory(dispatch),
  setCreateEvent: params => createEvent(dispatch, params),
  eventDetails: eventId => getEventDetails(dispatch, eventId),
});

const mapStateToProps = ({ usersReducer, eventData }) => ({
  usersReducer,
  eventData,
});

EventAdd.propTypes = {
  checkUserAccessPermission: PropTypes.func,
  eventData: PropTypes.object,
  eventDetails: PropTypes.func,
  getCategory: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  setCreateEvent: PropTypes.func,
};

const shell = compose(connect(mapStateToProps, mapDispatchToProps));
const core = compose(AuthenticationAccessPages);

export default shell(core(EventAdd));
