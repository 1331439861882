import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
// Style
import './ButtonActionFooterStyle.scss';
// Component
import { ButtonMain, PrevNextStepperCount } from '../../../../../components/Index';

const ButtonActionFooter = props => {
  const {
    visiblePrev,
    visibleSubmit,
    isLoading,
    onNextClick,
    onPrevClick,
    onCancelClick,
    onSubmitClick,
  } = props;

  return (
    <Grid container className="container-card-button-action">
      <Grid item lg={12} md={12}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item lg={6} md={6}>
            <div className="container-button-action-start">
              <div className="container-button">
                <ButtonMain
                  type="negative"
                  size="xl"
                  labelText="Cancel"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={onCancelClick}
                />
              </div>
              <div className="container-button">
                <ButtonMain
                  type="submit"
                  size="xl"
                  labelText="Save"
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={onSubmitClick}
                />
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6}>
            <div className="container-button-action-end">
              <div className="container-button">
                <ButtonMain
                  type="ghost"
                  size="xl"
                  labelText="Prev"
                  isLoading={isLoading}
                  disabled={isLoading || !visiblePrev}
                  onClick={onPrevClick}
                />
              </div>
              <div className="container-button">
                <ButtonMain
                  type="primary"
                  size="xl"
                  labelText="Next"
                  isLoading={isLoading}
                  disabled={isLoading || visibleSubmit}
                  onClick={onNextClick}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ButtonActionFooter.propTypes = {
  isLoading: PropTypes.bool,
  onCancelClick: PropTypes.func,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  visiblePrev: PropTypes.bool,
  visibleSubmit: PropTypes.bool,
};

export default PrevNextStepperCount(ButtonActionFooter);
