const Images = {
  fitcoLogoRoundedWhite: '../../src/assets/images/logo/logo-inverse-mobile.png',
  fitcoLogoRounded: '../../src/assets/images/logo/img-fitco-watermark.png',
  fitcoLogoWhite: '../../src/assets/images/logo/logo-inverse.png',
  fitcoLogo: 'https://cdn.fitco.id/landing/assets/new_fitco_black.svg',
  fitcoLogoLocal: '../../src/assets/images/logo/fitco-logo.png',
  fitmeLogo: '../../src/assets/images/logo/fitme-logo-hitam.png',
  mainPageSignIn: '../../src/assets/images/signIn/img_banner_login.png',
  mainPagePageNotFound: '../../src/assets/images/pageNotFound/img_err_404.png',
  avatarPlaceholder: '../../src/assets/images/profile/img-placeholder-profile.png',
  emptyStatePlaceholder: '../../src/assets/images/placeholder/img_no_activity.png',
  emptyStateNoHistory: '../../src/assets/images/placeholder/img_no_history.png',
  imgVABCA: '../../src/assets/images/bank/img_va_bca.png',
  imgVABNI: '../../src/assets/images/bank/img_va_bni.png',
  imgVAPermata: '../../src/assets/images/bank/img_va_permata.png',
  imgVAMandiri: '../../src/assets/images/bank/img_va_mandiri.png',
  imgVABRI: '../../src/assets/images/bank/img_va_bri.png',
  imgDeviceAndroid: '../../src/assets/images/mobile-devices/img_andorid.png',
  imgDeviceIos: '../../src/assets/images/mobile-devices/img_ios.png',
  instagramLogo: '../../src/assets/images/logo/logo-instagram.png',
  // shipment
  shippingLogoFitco: '../../src/assets/images/shipment/img_fitco_logo_color.png',
  ShippingLogoJne: '../../src/assets/images/shipment/img_jne.png',
  ShippingArrived: '../../src/assets/images/shipment/ic_step_arrived.png',
  ShippingArrivedActive: '../../src/assets/images/shipment/ic_step_arrived_active.png',
  ShippingDelivery: '../../src/assets/images/shipment/ic_step_delivery.png',
  ShippingDeliveryActive: '../../src/assets/images/shipment/ic_step_delivery_active.png',
  ShippingPackaging: '../../src/assets/images/shipment/ic_step_packaging.png',
  ShippingPackagingActive: '../../src/assets/images/shipment/ic_step_packaging_active.png',
  // usage
  membershipUsage: '../../src/assets/images/usage/ic_memberhips_applied.png',
  firstTimePurchaseUsage: '../../src/assets/images/usage/ic_first_purchased.png',
  // card image
  redCard: '../../src/assets/images/cardImage/img_expiring.png',
  purpleCard: '../../src/assets/images/cardImage/img_new_sales.png',
  orangeCard: '../../src/assets/images/cardImage/img_recurring_sales.png',
  blueCard: '../../src/assets/images/cardImage/img_total_conduct.png',
  // placeholder
  imageEatNotFound: '../../src/assets/images/placeholder/img_eats_placeholder.png',
  // event
  eventHeader: '../../src/assets/images/logo/event-header-bg.png',
  appStore: '../../src/assets/images/logo/app-store.png',
  googlePlayStore: '../../src/assets/images/logo/google-play-store.png',
  instagram: '../../src/assets/images/logo/instagram-line.png',
  tiktok: '../../src/assets/images/logo/tiktok-line.png',
  whatsapp: '../../src/assets/images/logo/whatsapp-line.png',
  phone: '../../src/assets/images/logo/phone-line.png',
};
export default Images;
