import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// component
import {
  PrevNextStepperCount,
  CollapsiblePanel,
  LabelInput,
  TextInput,
  TextEditor,
  ColorsPicker,
  SelectInputMain,
  ButtonMain,
} from '../../../../../components/Index';
// style
import '../../EventCMSAddStyle.scss';

const socmedOpt = [
  { value: 'instagram', name: 'Instagram' },
  { value: 'tiktok', name: 'Tiktok' },
  { value: 'whatsapp', name: 'Whatsapp' },
  { value: 'youtube', name: 'Youtube' },
  { value: 'facebook', name: 'Facebook' },
];

class StepCreateRules extends React.Component {
  handleAddItem = key => {
    const { initialForm, form, onChange } = this.props;
    const defaultValue = initialForm[key].lists[0] || {};
    const updatedList = [...(form[key].lists || []), { ...defaultValue }];
    onChange(`${key}.lists`, updatedList);
  };

  handleRemoveItem = (key, index) => {
    const { form, onChange } = this.props;
    const updatedList = form[key].lists.filter((_, i) => i !== index) || [];
    onChange(`${key}.lists`, updatedList);
  };

  getItems = (form, validation, panelStyle, onChange) => [
    {
      key: '1',
      label: 'Event Rules',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Title" isRequired />
                <TextInput
                  placeHolderText="Enter Title"
                  currentValue={form.rules.title}
                  size="md"
                  isError={!!validation['rules.title']}
                  errorMessage={validation['rules.title']}
                  onChange={value => onChange('rules.title', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Background Color" />
                    <ColorsPicker
                      value={form.rules.backgroundColor}
                      onChange={value => onChange('rules.backgroundColor', value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Text Color" />
                    <ColorsPicker
                      value={form.rules.color}
                      onChange={value => onChange('rules.color', value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {form.rules.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index} alignItems="center">
                <Grid item lg={12} md={12}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText={`Rule ${index + 1}`}
                      isRequired
                      showDelete={form.rules.lists.length > 1}
                      deleteText="Delete this rule"
                      onDelete={() => this.handleRemoveItem('rules', index)}
                    />
                    <TextInput
                      placeHolderText="Enter Description"
                      currentValue={item.description}
                      size="md"
                      isError={!!validation[`rules.lists[${index}].description`]}
                      errorMessage={validation[`rules.lists[${index}].description`]}
                      onChange={value => onChange(`rules.lists[${index}].description`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Rule"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('rules')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '2',
      label: 'Event Benefit',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Title" isRequired />
                <TextInput
                  placeHolderText="Enter Title"
                  currentValue={form.benefits.title}
                  size="md"
                  isError={!!validation['benefits.title']}
                  errorMessage={validation['benefits.title']}
                  onChange={value => onChange('benefits.title', value)}
                />
              </FormControl>
            </Grid>
            <Grid item lg={6} md={6}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Background Color" />
                    <ColorsPicker
                      value={form.benefits.backgroundColor}
                      onChange={value => onChange('benefits.backgroundColor', value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Text Color" />
                    <ColorsPicker
                      value={form.benefits.color}
                      onChange={value => onChange('benefits.color', value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {form.benefits.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index} alignItems="center">
                <Grid item lg={12} md={12}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText={`Benefit ${index + 1}`}
                      isRequired
                      showDelete={form.benefits.lists.length > 1}
                      deleteText="Delete this benefit"
                      onDelete={() => this.handleRemoveItem('benefits', index)}
                    />
                    <TextInput
                      placeHolderText="Enter Description"
                      currentValue={item.description}
                      size="md"
                      isError={!!validation[`benefits.lists[${index}].description`]}
                      errorMessage={validation[`benefits.lists[${index}].description`]}
                      onChange={value => onChange(`benefits.lists[${index}].description`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Benefit"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('benefits')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '3',
      label: 'Event FAQ',
      children: (
        <div>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <LabelInput labelText="Background Color" />
                <ColorsPicker
                  value={form.faq.backgroundColor}
                  onChange={value => onChange('faq.backgroundColor', value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          {form.faq.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index} alignItems="center">
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText={`Question ${index + 1}`} isRequired />
                    <TextEditor
                      currentValue={item.question}
                      isError={!!validation[`faq.lists[${index}].question`]}
                      errorMessage={validation[`faq.lists[${index}].question`]}
                      onChange={value => onChange(`faq.lists[${index}].question`, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText={`Answer ${index + 1}`}
                      isRequired
                      showDelete={form.faq.lists.length > 1}
                      deleteText="Delete this set of FAQ"
                      onDelete={() => this.handleRemoveItem('faq', index)}
                    />
                    <TextEditor
                      currentValue={item.answer}
                      isError={!!validation[`faq.lists[${index}].answer`]}
                      errorMessage={validation[`faq.lists[${index}].answer`]}
                      onChange={value => onChange(`faq.lists[${index}].answer`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add FAQ"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('faq')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: '4',
      label: 'Social Media Account',
      children: (
        <div>
          {form.socialMedia.lists.map((item, index) => {
            return (
              <Grid container spacing={2} key={index}>
                <Grid item lg={4} md={4}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Social Media" isRequired />
                    <SelectInputMain
                      placeholder="Select Social Media"
                      options={socmedOpt}
                      currentValue={item.type}
                      validateStatus={!!validation[`socialMedia.lists[${index}].type`]}
                      errorMessage={validation[`socialMedia.lists[${index}].type`]}
                      onChange={value => onChange(`socialMedia.lists[${index}].type`, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput labelText="Display Name" isRequired />
                    <TextInput
                      placeHolderText="Enter Name"
                      currentValue={item.name}
                      size="md"
                      isError={!!validation[`socialMedia.lists[${index}].name`]}
                      errorMessage={validation[`socialMedia.lists[${index}].name`]}
                      onChange={value => onChange(`socialMedia.lists[${index}].name`, value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4}>
                  <FormControl component="fieldset" fullWidth margin="normal">
                    <LabelInput
                      labelText="Link"
                      isRequired
                      showDelete={form.socialMedia.lists.length > 1}
                      deleteText="Delete this account"
                      onDelete={() => this.handleRemoveItem('socialMedia', index)}
                    />
                    <TextInput
                      placeHolderText="Enter Link"
                      currentValue={item.link}
                      size="md"
                      isError={!!validation[`socialMedia.lists[${index}].link`]}
                      errorMessage={validation[`socialMedia.lists[${index}].link`]}
                      onChange={value => onChange(`socialMedia.lists[${index}].link`, value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            justify="center"
            alignItems="center"
            className="container-filter flex-wrap-unset mt-16"
            style={{ backgroundColor: '#FAFAFA', padding: '16px', borderRadius: '8px' }}
          >
            <Grid item xl={2} lg={2} md={2} className="row-filter">
              <ButtonMain
                type="sillhoutte"
                size="md"
                labelText="Add Account"
                startIcon="ic-ffo-add"
                onClick={() => this.handleAddItem('socialMedia')}
              />
            </Grid>
          </Grid>
        </div>
      ),
      style: panelStyle,
    },
  ];

  render() {
    const { form, validation, onChange } = this.props;
    const panelStyle = {
      border: '1px solid #F0F0F0',
      borderRadius: 8,
      backgroundColor: 'white',
      marginBottom: 24,
      padding: '16px',
    };

    return <CollapsiblePanel items={this.getItems(form, validation, panelStyle, onChange)} />;
  }
}

StepCreateRules.propTypes = {
  form: PropTypes.object,
  initialForm: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateRules));
