/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { connect } from 'react-redux';
import { Grid, FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
// component
import {
  PrevNextStepperCount,
  ButtonMain,
  TextInput,
  LabelInput,
  SelectInputMain,
  SwitchInput,
} from '../../../../../components/Index';
// helpers
import { CommonHelper } from '../../../../../helpers/Index';
// style
import '../../EventAddStyle.scss';

const initialForm = {
  question: '',
  type: null,
  is_required: true,
  has_notes: true,
};

const questionTypeOpt = [
  { value: 'radio', name: 'Radio' },
  { value: 'field', name: 'Field' },
];

class StepCreateQuestionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formQuestionnaire: [CommonHelper.objectCloning(initialForm)],
      formValidation: {},
    };
  }

  componentDidMount() {
    if (this.props.form.questionnaires.length > 0) {
      this.setState({ formQuestionnaire: this.props.form.questionnaires });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { form, validation, onChange } = this.props;
    const { formQuestionnaire } = this.state;

    if (prevProps.validation !== validation) {
      const updatedValidation = formQuestionnaire.map(item => {
        const errors = {};
        if (!item.question || item.question.trim() === '') {
          errors.question = `Question is required.`;
        }
        if (!item.type) {
          errors.type = `Type is required.`;
        }
        return errors;
      });

      this.setState({ formValidation: { questionnaires: updatedValidation } });
    }

    if (
      !_.isEqual(prevProps.form.questionnaires, form.questionnaires) &&
      form.questionnaires.length > 0
    ) {
      this.setState({ formQuestionnaire: form.questionnaires });
    }

    if (!_.isEqual(prevState.formQuestionnaire, formQuestionnaire) && onChange) {
      const updatedQuestionnaires = formQuestionnaire.map(item => ({
        question: item.question,
        type: item.type,
        is_required: item.is_required ? 1 : 0,
        has_notes: item.has_notes ? 1 : 0,
      }));
      onChange('questionnaires', updatedQuestionnaires);
    }
  }

  handleAddQuestionnaire = () => {
    const { onChange } = this.props;
    const { formQuestionnaire } = this.state;

    const newQuestionnaire = {
      question: '',
      type: null,
      is_required: true,
      has_notes: false,
    };

    const updatedFormQuestionnaire = [...formQuestionnaire, newQuestionnaire];

    this.setState({ formQuestionnaire: updatedFormQuestionnaire });

    if (onChange) {
      onChange(updatedFormQuestionnaire);
    }
  };

  handleRemoveQuestionnaire = index => {
    const { onChange } = this.props;
    const { formQuestionnaire } = this.state;

    if (index < 0 || index >= formQuestionnaire.length) {
      console.error(`Invalid index ${index} for removal.`);
      return;
    }

    const updatedQuestionnaires = formQuestionnaire.filter((_val, i) => i !== index);

    this.setState({ formQuestionnaire: updatedQuestionnaires }, () => {
      if (onChange) {
        onChange('questionnaires', updatedQuestionnaires);
      }
    });
  };

  handleFormChange = (index, key, value) => {
    const { formQuestionnaire } = this.state;
    const updatedQuestionnaires = [...formQuestionnaire];

    updatedQuestionnaires[index] = {
      ...updatedQuestionnaires[index],
      [key]: value,
    };

    this.setState({ formQuestionnaire: updatedQuestionnaires });
  };

  getValidationError = path => {
    return path.reduce(
      (acc, key) => (acc && acc[key] ? acc[key] : null),
      this.state.formValidation,
    );
  };

  render() {
    const { formQuestionnaire } = this.state;

    return (
      <div className="flex-column" style={{ gap: '24px' }}>
        <div className="container-step-create-kitpack flex-column mt-24" style={{ gap: '24px' }}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="container-filter flex-wrap-unset ph-32 pv-16 title-container"
          >
            <Grid item lg md>
              <label className="text-16 text-black text-semi-bold">Questionnaire</label>
            </Grid>
            <Grid item xl={3} lg={3} md={3} className="row-filter">
              <ButtonMain
                labelText="Add Questionnaire"
                onClick={this.handleAddQuestionnaire}
                type="primary"
                size="md"
                startIcon="ic-ffo-add"
              />
            </Grid>
          </Grid>
          <div className="ph-32 pb-32">
            {formQuestionnaire.map((item, index) => (
              <div key={index} className="questionnaire-container mb-24">
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Question" isRequired />
                      <TextInput
                        placeHolderText="Enter Question"
                        currentValue={item.question}
                        size="md"
                        isError={!!this.getValidationError(['questionnaires', index, 'question'])}
                        errorMessage={this.getValidationError([
                          'questionnaires',
                          index,
                          'question',
                        ])}
                        onChange={value => this.handleFormChange(index, 'question', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <LabelInput labelText="Type" isRequired />
                      <SelectInputMain
                        placeholder="Select Type"
                        options={questionTypeOpt}
                        currentValue={item.type}
                        validateStatus={
                          !!this.getValidationError(['questionnaires', index, 'type'])
                        }
                        errorMessage={this.getValidationError(['questionnaires', index, 'type'])}
                        onChange={value => this.handleFormChange(index, 'type', value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xl={6} lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <div className="flex-row align-center" style={{ gap: '8px' }}>
                            <label className="text-semi-bold text-12 text-rolling-stone">
                              Required?
                            </label>
                            <SwitchInput
                              currentValue={item.is_required}
                              onChange={value => this.handleFormChange(index, 'is_required', value)}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6}>
                        <FormControl component="fieldset" fullWidth margin={'normal'}>
                          <div className="flex-row align-center" style={{ gap: '8px' }}>
                            <label className="text-semi-bold text-12 text-rolling-stone">
                              Has notes?
                            </label>
                            <SwitchInput
                              currentValue={item.has_notes}
                              onChange={value => this.handleFormChange(index, 'has_notes', value)}
                            />
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {formQuestionnaire.length > 1 && (
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className="container-filter flex-wrap-unset mt-16"
                  >
                    <Grid item xl={2} lg={2} md={2} className="row-filter">
                      <ButtonMain
                        type="danger"
                        size="md"
                        labelText="Delete"
                        onClick={() => this.handleRemoveQuestionnaire(index)}
                      />
                    </Grid>
                  </Grid>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

StepCreateQuestionnaire.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  validation: PropTypes.object,
};

const shell = compose(connect());

export default shell(PrevNextStepperCount(StepCreateQuestionnaire));
